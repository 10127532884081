import React, { useState, forwardRef, useImperativeHandle } from "react";
import CustomButton from "../../components/CustomButton";
import loadingSpinner from "../../sources/lotties/loadingSpinner.json";
import Lottie from "react-lottie";

const EditModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    open(form, conversationData) {
      setCurrentForm(form);
      setImageUrl(conversationData);
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
  }));

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = (flowId, nodeId) => {
    setLoading(true);
    setTimeout(() => {
      window.botpressWebChat.sendEvent({
        type: "jumpTo",
        channel: "web",
        payload: {
          flowId: flowId,
          nodeId: nodeId,
        },
      });
      setLoading(false);
      setIsOpen(false);
    }, 3000);
  };

  const renderBorders = () => {
    switch (currentForm) {
      case "Behavior Chain":
        return (
          <>
            <div className="h-16 relative md:top-8 lg:top-10 xl:top-16 flex z-30 opacity-50  w-full">
              <div
                className="relative top-8 w-48 left-3 h-5  md:h-8 md:top-4 z-20 md:left-4 md:w-[18.5rem] lg:w-[20.6rem] lg:left-5 xl:w-[26rem] xl:left-6  xl:h-10 xl:top-2 border border-transparent hover:bg-blue-300  cursor-pointer rounded-md"
                onClick={() =>
                  handleClick("Behaviour-chain", "update-situation")
                }
              ></div>
              <div
                className="relative top-8 w-[7.5rem] left-8 h-8 md:h-12 md:left-12 z-30 md:w-[11.4rem] lg:left-14 lg:w-[13rem] xl:left-[4.5rem] xl:w-[16rem] xl:h-16 md:top-3 hover:bg-blue-300 cursor-pointer rounded-md "
                onClick={() =>
                  handleClick("Behaviour-chain", "update-feelings")
                }
              ></div>
            </div>
            <div className="h-16 flex opacity-50 z-20 relative md:h-24 lg:h-28 xl:h-36 w-full">
              <div
                className="hover:bg-blue-300 relative left-4 h-full w-32 md:w-52 md:top-12 z-40  md:left-5 lg:w-60 xl:w-72 xl:top-[5.5rem] xl:left-8 rounded-md cursor-pointer"
                onClick={() =>
                  handleClick("Behaviour-chain", "update-thoughts")
                }
              ></div>
              <div
                className="hover:bg-blue-300 h-8 w-[7.5rem] left-24 md:h-12 md:w-[11rem] md:left-36 md:top-8 lg:top-12 lg:w-[13rem] lg:left-40 xl:w-[15.8rem] xl:left-[12.4rem] xl:top-20 xl:h-16 relative rounded-md cursor-pointer"
                onClick={() =>
                  handleClick("Behaviour-chain", "update-new-feelings")
                }
              ></div>
              <div
                className="hover:bg-blue-300 h-8 w-[6.5rem] z-50 top-14 right-3 md:top-32 md:w-[10rem] md:h-12 md:right-5 lg:w-[12rem] lg:top-36 xl:w-[14rem] xl:top-52 xl:h-16 relative rounded-md cursor-pointer"
                onClick={() =>
                  handleClick("Behaviour-chain", "update-positive-consequences")
                }
              ></div>
            </div>
            <div className="h-16 z-10  md:h-28 lg:h-32 xl:h-36 flex relative opacity-50 w-full">
              <div
                className="relative left-4 w-32 top-3 z-40 md:w-52 md:top-12 md:left-5 lg:w-60 xl:w-72 xl:left-8 xl:top-[6rem] border border-transparent hover:bg-blue-300  cursor-pointer rounded-md"
                onClick={() =>
                  handleClick("Behaviour-chain", "update-new-thoughts")
                }
              ></div>
              <div className="flex flex-col h-full ">
                <div
                  className="relative border hover:bg-blue-300 z-20 h-6 w-24 left-28 top-8 md:top-20 md:left-40 md:w-40 md:h-12 lg:h-16 lg:w-[11.5rem] lg:top-24 lg:left-[10.5rem] xl:w-[14rem] xl:top-36 xl:left-[14.5rem] border-transparent cursor-pointer rounded-md"
                  onClick={() =>
                    handleClick(
                      "Behaviour-chain",
                      "update-new-positive-consequences"
                    )
                  }
                ></div>
                <div
                  onClick={() =>
                    handleClick(
                      "Behaviour-chain",
                      "update-negative-consequences"
                    )
                  }
                  className="relative  h-9 w-[6.3rem] left-[6.9rem] top-8 md:top-20 md:left-40 md:w-40 md:h-12 lg:h-14 lg:w-[11.5rem] lg:top-[5.5rem] lg:left-[10.5rem] xl:h-16 xl:top-[9rem] xl:left-[14.5rem] xl:w-[14rem] hover:bg-blue-300 border opacity-70 border-transparent cursor-pointer rounded-md"
                ></div>
              </div>
            </div>
            <div className="h-16  opacity-50  md:h-28 lg:h-32 xl:h-48 w-full">
              <div
                className="relative h-5 top-6 left-4 w-[8.2rem] md:h-8 md:left-5 md:w-[13rem] md:top-14 lg:h-12 lg:w-[15rem] xl:h-12 xl:w-[18rem] xl:top-28 xl:left-8 hover:bg-blue-300  border border-transparent cursor-pointer rounded-md"
                onClick={() => handleClick("Behaviour-chain", "update-actions")}
              ></div>
              <div
                className="relative w-[6rem] h-7 top-3 left-60 md:h-12 md:top-10 md:w-[10rem] md:left-[23rem] lg:left-[25.7rem] lg:w-[11rem] xl:left-[32.5rem] xl:w-[14rem] xl:top-24 hover:bg-blue-300 rounded-md"
                onClick={() =>
                  handleClick(
                    "Behaviour-chain",
                    "update-new-negative-consequences"
                  )
                }
              >
                {" "}
              </div>
              <div
                className="relative hover:bg-blue-300 h-3 w-[8rem] md:w-[13.2rem] md:h-8 md:left-5 left-4 lg:w-[15rem] xl:w-[18rem] xl:top-[4.4rem] xl:left-8 border border-transparent cursor-pointer rounded-md"
                onClick={() =>
                  handleClick("Behaviour-chain", "update-new-actions")
                }
              ></div>
            </div>
          </>
        );
      case "Social Skills":
        return (
          <>
            <div className="w-full h-full  opacity-70">
              <div className="w-full h-48 md:h-64 xl:h-80 top-24 opacity-70 relative ">
                <div
                  onClick={() =>
                    handleClick("social-skills-new", "update-social-skill")
                  }
                  className="w-[300px] left-8 h-28 -top-8 md:h-36 md:left-12 md:-top-4 md:w-[350px] xl:h-40 xl:w-[450px] xl:top-0 xl:left-12 relative  rounded-md hover:bg-blue-300"
                ></div>
                <div
                  onClick={() =>{
                    //handleClick("social-skills-new", "update-step-plans")
                  }
                  }
                  className="w-[300px] left-8 h-24 -top-10 md:h-[115px] md:left-12 md:-top-4 md:w-[350px] xl:h-40 xl:w-[450px] xl:top-0 xl:left-12 relative  rounded-md"
                ></div>
                <div
                  onClick={() =>
                    handleClick("social-skills-new", "update-anticipate")
                  }
                  className="w-[300px] left-8 h-8 -top-10 md:w-[350px] md:left-12 md:h-10 md:-top-4 xl:h-10 xl:left-12 xl:w-[450px] xl:top-0 relative rounded-md hover:bg-blue-300"
                ></div>
              </div>
            </div>
          </>
        );
      case "Cost Benefit Analysis":
        return (
          <>
            <div className="w-full h-full relative opacity-30">
              <div className="flex h-12 top-6 w-full md:h-[4.2rem] md:top-11 lg:h-[5rem] xl:h-[6.2rem] xl:top-14 relative  cursor-pointer">
                <div
                  onClick={() =>
                    handleClick("cost-benefit-analysis", "update-behavior-1")
                  }
                  className="h-4 w-28 md:h-7 md:w-[10rem] lg:w-[12rem] xl:w-[16rem] xl:h-10 xl:top-12 xl:left-[8rem] lg:top-10 lg:left-[6.5rem] md:top-8 md:left-24 top-6 left-[3.5rem] hover:bg-blue-300 rounded-md cursor-pointer  relative "
                ></div>
                <div
                  onClick={() =>
                    handleClick("cost-benefit-analysis", "update-behavior-2")
                  }
                  className="h-4 w-28 hover:bg-blue-300 top-6 left-[6.5rem] md:h-7 md:top-8 md:left-44 md:w-[11rem] lg:w-[12rem] lg:top-10 lg:left-[11.5rem] xl:top-12 xl:left-[13.5rem] xl:w-[16rem] xl:h-10 relative rounded-md cursor-pointer"
                ></div>
              </div>
              <div className="flex w-full h-[4.5rem]  top-10 md:h-[7rem] md:top-16 lg:h-[8rem] lg:top-[4.2rem] xl:h-[10rem] xl:top-[5.3rem] relative ">
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-short-term-benefits"
                    )
                  }
                  className="hover:bg-blue-300 cursor-pointer w-14 left-14 md:w-[5.5rem] md:left-[5.5rem] lg:w-[6rem] lg:left-[6.1rem] xl:w-[8rem] xl:left-[7.4rem] rounded relative h-full"
                ></div>
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-short-term-costs"
                    )
                  }
                  className="h-full hover:bg-blue-300 cursor-pointer w-14 left-[3.7rem] md:w-[5.5rem] md:left-[5.5rem] lg:w-[6.2rem] lg:left-[6.1rem] xl:w-[8rem] xl:left-[7.4rem] rounded relative"
                ></div>
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-alternate-short-term-benefits"
                    )
                  }
                  className="hover:bg-blue-300 cursor-pointer w-14 left-[6.3rem] md:w-[5.5rem] md:left-[9.5rem] lg:w-[6rem] lg:left-[10.7rem] xl:w-[7.4rem] xl:left-[13.2rem] relative h-full"
                ></div>
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-alternate-short-term-costs"
                    )
                  }
                  className="h-full hover:bg-blue-300 cursor-pointer w-14 left-[6.4rem] md:w-[5.5rem] md:left-[9.5rem] lg:w-[6.2rem] lg:left-[10.8rem] xl:w-[7.4rem] xl:left-[13.4rem] relative"
                ></div>
              </div>
              <div className="flex w-full h-[4.5rem]  top-10 md:h-[7rem] md:top-16 lg:h-[8rem] lg:top-[4.2rem] xl:h-[10rem] xl:top-[5.3rem]  relative ">
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-long-term-benefits"
                    )
                  }
                  className="hover:bg-blue-300 cursor-pointer w-14 left-14 md:w-[5.5rem] md:left-[5.5rem] lg:w-[6rem] lg:left-[6.1rem] xl:w-[8rem] xl:left-[7.4rem] rounded relative h-full"
                ></div>
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-long-term-costs"
                    )
                  }
                  className="h-full hover:bg-blue-300 cursor-pointer w-14 left-[3.7rem] md:w-[5.5rem] md:left-[5.5rem] lg:w-[6.2rem] lg:left-[6.1rem] xl:w-[8rem] xl:left-[7.4rem] rounded relative"
                ></div>
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-alternate-long-term-benefits"
                    )
                  }
                  className="hover:bg-blue-300 cursor-pointer w-14 left-[6.3rem] md:w-[5.5rem] md:left-[9.5rem] lg:w-[6rem] lg:left-[10.7rem] xl:w-[7.4rem] xl:left-[13.2rem] relative h-full"
                ></div>
                <div
                  onClick={() =>
                    handleClick(
                      "cost-benefit-analysis",
                      "update-alternate-long-term-costs"
                    )
                  }
                  className="h-full hover:bg-blue-300 cursor-pointer w-14 left-[6.4rem] md:w-[5.5rem] md:left-[9.5rem] lg:w-[6.2rem] lg:left-[10.8rem] xl:w-[7.4rem] xl:left-[13.4rem] relative"
                ></div>
              </div>
            </div>
          </>
        );
      case "Problem Solving":
        return (
          <>
            <div className="w-full h-full opacity-50 relative ">
              <div className="h-16 md:h-20 xl:h-32  top-20  relative w-full opacity-100">
                <div
                  onClick={() =>
                    handleClick("Problem-solving", "update-problem")
                  }
                  className="w-60 top-2 left-20 h-4 hover:bg-blue-300 md:top-8 md:left-24 md:h-6 md:w-80 xl:left-[7.8rem] xl:top-12 xl:h-8 xl:w-96 cursor-pointer rounded-md relative"
                ></div>
                <div
                  onClick={() => handleClick("Problem-solving", "update-goals")}
                  className="w-60 top-3 left-20 h-4 md:h-6 md:w-80 md:left-24 md:top-8 xl:left-[7.8rem] xl:top-12 xl:h-8 xl:w-96 relative  cursor-pointer hover:bg-blue-300 rounded-md"
                ></div>
              </div>
              <div className="h-20 md:h-36 xl:h-44 top-36  relative w-full ">
                <div
                  onClick={() =>
                    handleClick("Problem-solving", "update-options")
                  }
                  className="h-full w-[17.5rem] left-10 md:h-28 md:top-8 md:w-[22rem] md:left-14 xl:h-36 xl:w-[27rem] xl:left-16 rounded-md relative cursor-pointer hover:bg-blue-300"
                ></div>
              </div>
              <div className="h-20 md:h-36 xl:h-44  top-44 relative w-full">
                <div
                  onClick={() => handleClick("Problem-solving", "update-steps")}
                  className="h-14 w-72 rounded-md relative left-8  md:h-20 md:w-96 md:left-10 xl:h-24 xl:w-[28rem] xl:left-12  cursor-pointer hover:bg-blue-300"
                ></div>
                <div
                  onClick={() =>
                    handleClick("Problem-solving", "update-social-skill")
                  }
                  className="h-6 w-72 relative rounded-md left-8 md:left-10 md:w-96 xl:left-12 xl:w-[28rem] cursor-pointer hover:bg-blue-300"
                ></div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const getModalSize = () => {
    switch (currentForm) {
      case "Behavior Chain":
      case "Cost Benefit Analysis":
        return "w-96 md:w-[36rem] lg:w-[40rem] xl:w-[50rem]";
      case "Social Skills":
      case "Problem Solving":
        return "w-96 md:w-[30rem]  xl:w-[36rem]";
      default:
        return "w-96 md:w-[36rem] lg:w-[40rem] xl:w-[50rem]";
    }
  };

  const renderImage = () => {
    if (!imageUrl) return null;
    return (
      <img
        src={imageUrl}
        alt={currentForm}
        className="w-full h-full object-fill"
      />
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      {loading && (
        <div className="w-full h-full absolute z-50 bg-slate-200 opacity-50 flex flex-col items-center justify-center">
          <div className="w-32 h-32 mt-24">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingSpinner,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
          </div>
          <p className="mt-24 text-xl">Loading...</p>
        </div>
      )}
      <div
        className={`relative z-40 ${getModalSize()} bg-white rounded-lg p-4`}
      >
        <div className="relative mb-4 mt-2 flex justify-end gap-10">
          <div className="flex text-center text-black text-lg font-semibold p-2 bg-blue-100 rounded">
            <div className="flex text-xs md:text-md lg:text-lg  ">
              Select the part you want to edit.
            </div>
          </div>
          <CustomButton
            onClick={handleClose}
            buttonColor={"danger"}
            buttonType={"outline"}
            buttonSize={"sm"}
          >
            Close
          </CustomButton>
        </div>
        <div className="relative w-full h-full overflow-hidden">
          {renderImage()}
          <div className="absolute inset-0">{renderBorders()}</div>
        </div>
      </div>
    </div>
  );
});

export default EditModal;
