import PlusIcon from "../../../sources/icons/PlusIcon.js";

const QuickConversationButton = ({ openModal }) => {
  return (
    <div className="text-right">
        <div className="bg-[#6B7DBB] py-2 px-2 text-white flex flex-row items-center justify-between rounded-lg">
          <div
            className="flex flex-row items-center justify-center cursor-pointer mr-2"
            onClick={openModal}
          >
            <PlusIcon
              alt="Search Icon"
              height={20}
              width={20}
              strokeColor={"white"}
              className="cursor-pointer stroke-white text-white"
            />
            <div className="mt-0.5 ml-1 text-sm">Create</div>
          </div>
        </div>
    </div>
  );
};

export default QuickConversationButton;