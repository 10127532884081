import { useEffect, useState } from "react";
import injectChatbot from "../utils/chatbot/injectChatbot";
import chatbotInjectCleanup from "../utils/chatbot/chatbotInjectCleanup";
import { useSelector } from "react-redux";

function ChatbotProvider({ children }) {
  const [initialized, setInitialized] = useState(false);
  const {currentPageLocation} = useSelector((store) => store.appReducer)

  useEffect(() => {
    (async () => {
      await chatbotInjectCleanup();

      /*
      You shouldn't inject script tag again if script is already injected
      because injected file not going to be removed even if you remove script tag
      */
      if (!initialized) {
        await injectChatbot();
        setInitialized(true);
      }
    })()
    // eslint-disable-next-line
  }, [currentPageLocation]);

  return <>{children}</>;
}

export default ChatbotProvider;
