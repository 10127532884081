import React, { useState } from 'react';
import CustomModal from '../../../components/CustomModal';
import CustomButton from "../../../components/CustomButton";

const NewOffenderModal = ({ isOpen, closeModal, handleCreateNewOffender }) => {
  const [userDetails, setUserDetails] = useState({});

  const handleInputChange = (event) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value })
  }

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={closeModal}
      titleStyle={"w-full flex items-center justify-center mb-2"}
      modalStyle={"min-w-[30%] max-w-[70%"}
    >
      <h1 className='text-3xl font-bold text-center'>Add New Offender</h1>
      <form onSubmit={(event) => { event.preventDefault() }} className='flex flex-col'>
        <div className="flex flex-row gap-5 m-2">
          <div className="flex flex-col gap-1">
            <label htmlFor="">First Name</label>
            <input className='rounded-md border-2 border-black-50 px-1 py-1' type="text" name="firstName" value={userDetails.firstName ? userDetails.firstName : ''} onChange={handleInputChange} />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="">Last Name</label>
            <input className='rounded-md border-2 border-black-50 px-1 py-1' type="text" name="lastName" value={userDetails.lastName ? userDetails.lastName : ''} onChange={handleInputChange} />
          </div>
        </div>
        
        <div className="flex flex-col gap-1 m-2">
          <label htmlFor="">Username</label>
          <input className='rounded-md border-2 border-black-50 px-1 py-1' type="text" name="username" value={userDetails.username ? userDetails.username : ''} onChange={handleInputChange} />
        </div>
        <div className="flex flex-col gap-1 m-2">
          <label htmlFor="">Email</label>
          <input className='rounded-md border-2 border-black-50 px-1 py-1' type="text" name="email" value={userDetails.email ? userDetails.email : ''} onChange={handleInputChange} />
        </div>

        <CustomButton
          onClick={(event) => handleCreateNewOffender(event, setUserDetails, userDetails)}
          buttonSize="sm"
          buttonType="outline"
          buttonColor="primary"
          customStyle='m-2 self-end'
        >
          <span className="flex flex-row gap-2 items-center justify-center">
            Submit
          </span>   
        </CustomButton>
      </form>
    </CustomModal>
  )
}

export default NewOffenderModal;
