import React, { forwardRef, useImperativeHandle, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { makeGetRequest } from "../../utils/makeRequest";
import { toast } from "react-toastify";

const VideoPreview = ({ src }) => {
  return (
    <video
      width={500}
      height={500}
      autoPlay
      src={src}
      controls
      style={{ borderRadius: 20, borderWidth: 8 }}
    />
  );
};

//eslint-disable-next-line
const DisplayVideo = forwardRef(function VideoRecorder({ onClose }, ref) {
  const [showDisplayVideo, setDisplayVideoStatus] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const showVideoDisplayer = ({
    currentConversationId: currentConversation,
  }) => {
    pullVideoUrl(currentConversation);
    setDisplayVideoStatus(true);
  };

  const hideVideoDisplayer = () => {
    onClose();
    setDisplayVideoStatus(false);
  };

  useImperativeHandle(ref, () => ({
    showVideoDisplayer,
    hideVideoDisplayer,
  }));

  const navigate = useNavigate();
  const location = useLocation();

  const pullVideoUrl = async (currentConversation) => {
    const response = await makeGetRequest({
      path: "/api/conversation/pull-last-conversation-recording/" + currentConversation,
      config: {
        headers: {
          "Content-Type": "application/json",
        },
      }, navigate, location
    });
    if (response.data.success) {
      setVideoUrl(response.data.data.url);
    }
  };

  const renderDisplayVideo = () => {
    if (showDisplayVideo) {
      return (
        <div className="absolute flex-1 w-screen h-screen z-20 flex items-center justify-center">
          <div className="absolute bg-white z-50 w-3/4 md:w-2/3 p-12 rounded-xl flex opacity-100 flex-row">
            <div className="w-full h-full items-center justify-center flex flex-col">
              {videoUrl && <VideoPreview src={videoUrl} />}
              <div className="w-full flex justify-center items-center mt-8">
                <CustomButton
                  buttonColor={"danger"}
                  buttonType={"solid"}
                  buttonSize={"lg"}
                  onClick={() => {
                    setTimeout(() => {
                      window.botpressWebChat.sendEvent({
                        type: "proactive-trigger",
                        channel: "web",
                        payload: { text: "Video displayed!" },
                      });
                    }, 500);
                    hideVideoDisplayer();
                  }}
                >
                  Close
                </CustomButton>
              </div>
            </div>
          </div>
          <div className="w-screen h-screen z-30 bg-zinc-800 opacity-80"></div>
        </div>
      );
    }
  };

  return <>{renderDisplayVideo()}</>;
});

export default DisplayVideo;
