import React, { Fragment } from "react";
import { CloseButton, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import CloseIcon from "../sources/icons/CloseIcon.svg";

const CustomModal = ({ isOpen, onClose, title, titleStyle, modalStyle, children }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className={`relative z-50 min-w-full`} onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className={`fixed inset-0 overflow-y-auto`}>
          <div className="flex h-full w-full items-center justify-center px-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={`${modalStyle && modalStyle} transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
              >
                <DialogTitle
                  as="div"
                  className={`text-lg font-medium leading-6 text-gray-900 flex justify-between ${titleStyle && titleStyle}`}
                >
                  <h3>{title}</h3>
                  <CloseButton className="z-20"><img src={CloseIcon} height={20} width={20} alt="" /></CloseButton>
                </DialogTitle>
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};


export default CustomModal;
