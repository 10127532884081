import { botpressUrl } from "../constants";

function injectChatbot() {
  return new Promise((resolve) => {
    const scripts = document.getElementsByTagName("script");

    let script;
    let isScriptInjected = false;
    for (let i = 0; i < scripts.length; i++) {
      if (
        scripts[i].getAttribute("src") ===
        botpressUrl + "/assets/modules/channel-web/inject.js"
      ) {
        isScriptInjected = true;
        script = scripts[i];
      }
    }

    if (!isScriptInjected) {
      script = document.createElement("script");
      script.src = botpressUrl + "/assets/modules/channel-web/inject.js";
      script.id = "inject-chat";
      script.async = true;

      document.head.appendChild(script);
    }
    resolve()
  });
}

export default injectChatbot;
