import axios from 'axios';
import { baseUrl } from './constants';

const makeGetRequest = async ({ path, config: { headers = {}, params = {} } = {}, navigate, location }) => {
  if (path === null) {
    return "Invalid request call."
  }
  try {
    const response = await axios.get(baseUrl + path, { headers, params });
    return response;
  } catch (err) {
    if (navigate && location)
      navigate('/login', { state: { from: location }, replace: true });
  }
};

const makePostRequest = async ({ path, body, config: { headers = {}, params = {} } = {}, navigate, location }) => {
  if (path === null || navigate == null || location == null) {
    return "Invalid request call."
  }
  try {
    const response = await axios.post(baseUrl + path, body, { headers, params });
    return response;
  } catch (err) {
    navigate('/login', { state: { from: location }, replace: true });
  }
};

const makePatchRequest = async ({ path, body, config: { headers = {}, params = {} } = {}, navigate, location }) => {
  if (path === null || navigate == null || location == null) {
    return "Invalid request call."
  }
  try {
    const response = await axios.patch(baseUrl + path, body, { headers, params });
    return response;
  } catch (err) {
    navigate('/login', { state: { from: location }, replace: true });
  }
};

export { makeGetRequest, makePostRequest, makePatchRequest }
