import React, { useRef, useState } from "react";
import Avatar from "../../sources/lotties/avatar.json";
import Lottie from "react-lottie";
import TypingText from "../../components/TypingText";
import CustomButton from "../../components/CustomButton";
import Speaker from "../../sources/icons/Speaker";
import MuteSpeaker from "../../sources/icons/MuteSpeaker";
import RefreshIcon from "../../sources/icons/RefreshIcon";

function ShowAvatar({ avatarText, setShowAvatarStatus }) {
  const typingTextRef = useRef(null);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [toggleSpeaker, setToggleSpeaker] = useState(false);

  return (
    <div className="absolute flex-1 w-screen h-screen z-20 flex items-center justify-center">
      <div className="absolute z-50 flex opacity-100 flex-row">
        <Lottie
          className="z-50"
          options={{
            loop: true,
            autoplay: true,
            animationData: Avatar,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
        <div className="flex flex-col gap-4 items-end">
          <div className="flex w-72 flex-row items-start">
            <button
              type="button"
              className="avatar-speech-button-wrapper bg-[#FFEACE] p-2 cursor-pointer rounded-md"
              onClick={() => {
                setToggleSpeaker(!toggleSpeaker);
                typingTextRef.current?.toggleVolume();
              }}
            >
              {!toggleSpeaker ? (
                <Speaker width={24} height={24} />
              ) : (
                <MuteSpeaker width={14} height={14} />
              )}
            </button>
            {toggleSpeaker && (
              <button
                type="button"
                className="avatar-speech-button-wrapper bg-[#FFEACE] p-2 cursor-pointer rounded-md ml-2"
                onClick={() => {
                  typingTextRef.current?.restartSpeech();
                }}
              >
                <RefreshIcon width={24} height={24} />
              </button>
            )}
          </div>
          <div className="bg-white -ml-36 w-72 rounded-md h-fit py-4 px-4">
            <TypingText
              ref={typingTextRef}
              text={avatarText}
              delay={68}
              onContinueStatusChange={() => {
                setShowContinueButton(true);
              }}
              onCompleted={() => {
                setShowCloseButton(true);
                setToggleSpeaker(false);
              }}
            />
          </div>
          {showCloseButton && (
            <CustomButton
              onClick={() => {
                typingTextRef.current?.cancelSpeech();
                setTimeout(() => {
                  window.botpressWebChat.sendEvent({
                    type: "proactive-trigger",
                    channel: "web",
                    payload: { text: "Overview completed!" },
                  });
                  setShowAvatarStatus(false);
                  setShowCloseButton(false);
                }, 1500);
              }}
              buttonSize="md"
              buttonType="outline"
              buttonColor="danger"
              customStyle=""
            >
              close
            </CustomButton>
          )}
          {showContinueButton && (
            <CustomButton
              onClick={() => {
                typingTextRef.current?.continueRenderingLetters(toggleSpeaker);
                setShowContinueButton(false);
              }}
              buttonSize="md"
              buttonType="solid"
              buttonColor="primary"
              customStyle=""
            >
              Continue
            </CustomButton>
          )}
        </div>
      </div>
      <div className="w-screen h-screen z-30 bg-zinc-800 opacity-80"></div>
    </div>
  );
}

export default ShowAvatar;
