import CustomButton from "../../../components/CustomButton";

function ViewTool({ conversationId, imageUrl, onClose }) {
    return (
      <div 
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40"
        onClick={onClose}
      >
        <div 
          className="absolute w-5/6 md:w-2/3 xl:max-w-fit h-4/5 bg-white rounded-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative m-2 flex justify-end">
            <CustomButton
              buttonColor={"danger"}
              buttonType={"outline"}
              buttonSize={"sm"}
              onClick={onClose}
            >
              Close
            </CustomButton>
          </div>     
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Tool"
                className="w-full h-full rounded-lg  "
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center text-gray-500">
                No image available
              </div>
            )}
          </div>
        </div>
    );
  }

export default ViewTool;

