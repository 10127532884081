import HomePage from '../pages/home/HomePage';
import LoginPage from '../pages/login/LoginPage';
import ProtectedRoute from '../components/ProtectedRoute';
import RegisterPage from '../pages/register/RegisterPage';
import WelcomePage from '../pages/welcome/WelcomePage';
import DashboardPage from "../pages/dashboard/DashboardPage";
import OffenderPage from '../pages/admin/offender/OffenderPage';
import AdminDashboard from '../pages/admin/AdminDashboard';
import Home from '../pages/admin/home/HomePage';
import Supervisor from '../pages/admin/supervisor/Supervisor';
import Analytics from '../pages/admin/analytics/Analytics';
import ConversationAnalytics from '../pages/admin/analytics/ConversationAnalytics';
import ConversationConditions from '../pages/admin/analytics/ConversationConditions';
import RegisterOTP from '../pages/register-otp/RegisterOTP';

export const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/welcome",
    element: (
      <ProtectedRoute>
        <WelcomePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/register-otp",
    element: (
      <RegisterOTP />   
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <AdminDashboard />
      </ProtectedRoute>
    ),
    children: [
      { path: 'home', element: <Home /> },
      { path: 'supervisor', element: <Supervisor /> },
      { path: 'offender', element: <OffenderPage /> },
      { path: 'analytics/conversations', element: <ConversationAnalytics /> },
      { path: 'analytics/conditions', element: <ConversationConditions /> },
      { path: 'analytics', element: <Analytics /> },
    ]
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
  },
];