import { botpressUrl } from "../constants";

function chatbotInjectCleanup() {
  return new Promise((resolve) => {
    //cleanup stylesheets
    const links = document.getElementsByTagName("link");
    for (let i = 0; i < links.length; i++) {
      if (
        links[i].getAttribute("href") ===
        botpressUrl + "/assets/modules/channel-web/inject.css"
      ) {
        console.log("css file => ", links[i].getAttribute("href"));
        links[i].parentNode.removeChild(links[i]);
      }
    }

    resolve()
  });
}

export default chatbotInjectCleanup;
