import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import VideoRecorder from "./VideoRecorder";
import BehaviorChainForm from "./BehaviorChainForm";
import CostBenefitForm from "./CostBenefitForm";
import ProblemSolving from "./ProblemSolvingForm";
import DisplayVideo from "./DisplayVideo";
import SocialSkillForm from "./SocialSkillsForm";
import ShowAvatar from "./ShowAvatar";

const FlowProvider = forwardRef(function FlowProvider(
  {
    avatar: { showAvatar, avatarText, setShowAvatarStatus },
    displayBehaviorChainForm: {
      setShowBehaviorChainForm,
      behaviorChainFormText,
      showBehaviorChainForm,
    },
    displayCostBenefitForm: {
      setShowCostBenefitForm,
      costBenefitFormData,
      showCostBenefitForm,
    },
    displayProblemSolvingForm: {
      setShowProblemSolvingForm,
      problemSolvingFormData,
      showProblemSolvingForm,
    },
    displaySocialSkillForm: {
      setShowSocialSkillForm,
      socialSkillFormData,
      showSocialSkillForm,
    },
    onClose
  },
  ref
) {
  const videoRecorderRef = useRef(null);
  const displayVideoRef = useRef(null);

  useImperativeHandle(ref, () => ({
    videoRecorder: {
      showVideoRecorder: ({ title, description, currentConversationId }) => {
        if (videoRecorderRef.current) {
          videoRecorderRef.current.showVideoRecorder({
            title,
            description,
            currentConversationId,
          });
        }
      },
      hideVideoRecorder: () => {
        if (videoRecorderRef.current) {
          videoRecorderRef.current.hideVideoRecorder();
        }
      },
      uploadingVideoStatus: videoRecorderRef.current?.uploadingVideoStatus
    },
    displayVideo: {
      showVideoDisplayer: ({ currentConversationId }) => {
        if (displayVideoRef.current) {
          displayVideoRef.current.showVideoDisplayer({ currentConversationId });
        }
      },
      hideVideoDisplayer: () => {
        if (displayVideoRef.current) {
          displayVideoRef.current.hideVideoDisplayer();
        }
      },
    },
  }));

  return (
    <>
      <VideoRecorder ref={videoRecorderRef} onClose={onClose} />
      <DisplayVideo ref={displayVideoRef} onClose={onClose} />
      {showAvatar && <ShowAvatar avatarText={avatarText} setShowAvatarStatus={setShowAvatarStatus} />}
      {showBehaviorChainForm && (
        <BehaviorChainForm
          imageText={behaviorChainFormText}
          setShowImage={setShowBehaviorChainForm}
          closeModal={() => { setShowBehaviorChainForm(false); onClose() }}
        />
      )}
      {showCostBenefitForm && (
        <CostBenefitForm
          costBenefitFormData={costBenefitFormData}
          setShowCostBenefitForm={setShowCostBenefitForm}
          closeModal={() => { setShowCostBenefitForm(false); onClose() }}
        />
      )}
      {showProblemSolvingForm && (
        <ProblemSolving
          problemSolvingFormData={problemSolvingFormData}
          setShowProblemSolvingForm={setShowProblemSolvingForm}
          closeModal={() => { setShowProblemSolvingForm(false); onClose() }}
        />
      )}
      {showSocialSkillForm && (
        <SocialSkillForm
          socialSkillFormData={socialSkillFormData}
          setShowSocialSkillForm={setShowSocialSkillForm}
          closeModal={() => { setShowSocialSkillForm(false); onClose() }}
        />
      )}
    </>
  );
});
export default FlowProvider;
