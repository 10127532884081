import React, { Fragment, useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal";
import loadingSpinner from "../../../sources/lotties/loadingSpinner.json";
import ghostAnimation from "../../../sources/lotties/ghostAnimation.json";
import Lottie from "react-lottie";
import { store } from "../../../reducers/store";
import moment from "moment";
import VideoThumbnail from "../../../components/VideoThumbnail";
import { Select } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeGetRequest } from "../../../utils/makeRequest";

const AttachmentsModal = ({ isOpen, closeModal }) => {
  const [activeConversationCategoryIndex, setActiveConversationCategoryIndex] =
    useState(0);
  const currentConversation = store.getState().appReducer.currentConversation;
  const [isLoading, setLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [toolForms, setToolForms] = useState([]);
  const [cameraRecordings, setCameraRecordings] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchAttachments = async () => {
    try {
      const conversationId = currentConversation.conversationId;

      const response = await makeGetRequest({
        path: "/api/conversation/pull-attachments/" + conversationId,
        config: {
          headers: {
            "Content-Type": "application/json",
          },
        }, navigate, location
      });
      const mediaData = response.data.data;

      setLoading(false);

      setToolForms([]);
      setCameraRecordings([]);
      setResources([]);
      mediaData.forEach((media) => {
        if (media.name === "tool-print-form") {
          setToolForms((toolForms) => [...toolForms, media]);
        } else if (media.name === "video-recording") {
          setCameraRecordings((cameraRecordings) => [
            ...cameraRecordings,
            media,
          ]);
        } else {
          //setResources((resources) => [...resources, media]);
        }
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      fetchAttachments();
    }

    // eslint-disable-next-line
  }, [isOpen]);

  const menu = [
    { title: "Tool Forms" },
    { title: "Camera Recordings" },
    { title: "Shared Resources" },
  ];

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => {
        closeModal();
      }}
      titleStyle={"w-full flex items-center justify-left"}
      modalStyle={"w-full md:w-3/4  h-3/4"}
      title="Attachments"
    >
      <div className="w-full h-full items-center justify-start">
        <ul className="no-underline hidden md:flex flex-row text-md justify-start gap-12 border-t-2 mt-4 cursor-pointer pt-4 pb-4">
          {menu.map((v, i) => {
            return (
              <button
                onClick={() => {
                  setActiveConversationCategoryIndex(i);
                }}
                key={i}
              >
                <li
                  className={`w-auto ${
                    i === activeConversationCategoryIndex &&
                    "border-b-2 border-[#6B7DBB]"
                  }`}
                >
                  {v.title}
                </li>
              </button>
            );
          })}
        </ul>
        <div className="w-full md:hidden flex border-t-2 mt-4"></div>
        <div className="w-full md:hidden bg-gray-100 px-2 rounded-md mt-4 mb-4">
          <Select name="attachment-type" as={Fragment}>
            {({ focus, hover }) => (
              <select
                defaultValue={menu[activeConversationCategoryIndex]}
                onChange={(v) => {
                  const index = menu.findIndex((item) => item.title === v.target.value);
                  setActiveConversationCategoryIndex(index);
                }}
                className={`w-full bg-gray-100 py-2`}
                aria-label="Attachment Type"
              >
                {menu.map((v, i) => (
                  <option value={v.title}>{v.title}</option>
                ))}
              </select>
            )}
          </Select>
        </div>
        <div className="h-full pb-36">
          {isLoading ? (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <div className="w-32 h-32 mt-24">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingSpinner,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
              <p className="mt-24 text-xl">Loading...</p>
            </div>
          ) : (
            <div className="w-full overflow-y-scroll h-full flex flex-row flex-wrap gap-6 items-start justify-start">
              {activeConversationCategoryIndex === 0 &&
                toolForms.map((toolForm, index) => {
                  return (
                    <a
                      href={toolForm.attachment}
                      target="_blank"
                      key={index}
                      rel="noreferrer"
                      className="w-26 md:w-32 p-2 flex-col flex items-center justify-center relative"
                    >
                      <div className="w-24 h-24 items-center justify-center flex border-2 border-[#6B7DBB] rounded-md">
                        <img
                          src={toolForm.attachment}
                          alt={toolForm.name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <p className="text-sm">
                        Tool Form{" "}
                        <div
                          className={`${
                            toolForms.length > 1 && index === 0
                              ? "visible"
                              : "hidden"
                          } absolute right-0 top-0 bg-[#47A18A] text-white text-xs p-1 rounded-md`}
                        >
                          LATEST
                        </div>
                      </p>
                      <p className="text-sm text-[#A7ADB7]">
                        {moment(toolForm.createdAt).format("MM/YY/DD HH:mm")}
                      </p>
                    </a>
                  );
                })}
              {activeConversationCategoryIndex === 1 &&
                cameraRecordings.map((cameraRecording, index) => {
                  return (
                    <a
                      key={index}
                      href={cameraRecording.attachment}
                      target="_blank"
                      rel="noreferrer"
                      className="w-26 md:w-32 flex-col flex items-center justify-center"
                    >
                      <div className="w-24 h-24 items-center justify-center flex border-2 border-[#6B7DBB] rounded-md">
                        <VideoThumbnail
                          src={cameraRecording.attachment}
                          alt={cameraRecording.name}
                        />
                      </div>
                      <p className="text-sm">Camera Recording</p>
                      <p className="text-sm text-[#A7ADB7]">
                        {moment(cameraRecording.createdAt).format(
                          "MM/YY/DD HH:mm"
                        )}
                      </p>
                    </a>
                  );
                })}
              {activeConversationCategoryIndex === 2 &&
                resources.map((resource, index) => {
                  return (
                    <div
                      key={index}
                      className="w-24 flex-col flex items-center justify-center"
                    >
                      <div className="w-24 h-24 items-center justify-center flex border-2 border-[#6B7DBB] rounded-md">
                        <img
                          src={resource.attachment}
                          alt={resource.name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <p>{resource.name}</p>
                    </div>
                  );
                })}
              {activeConversationCategoryIndex === 0 &&
                toolForms.length === 0 && (
                  <div className="w-full h-full flex flex-col items-center justify-center">
                    <div className="w-52 h-52 -mb-24">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: ghostAnimation,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                      />
                    </div>
                    <p className="mt-24 text-xl">
                      There is no tool form yet...
                    </p>
                  </div>
                )}
              {activeConversationCategoryIndex === 1 &&
                cameraRecordings.length === 0 && (
                  <div className="w-full h-full flex flex-col items-center justify-center">
                    <div className="w-52 h-52 -mb-24">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: ghostAnimation,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                      />
                    </div>
                    <p className="mt-24 text-xl text-center">
                      There is no camera recordings...
                    </p>
                  </div>
                )}
              {activeConversationCategoryIndex === 2 &&
                resources.length === 0 && (
                  <div className="w-full h-full flex flex-col items-center justify-center">
                    <div className="w-52 h-52 -mb-24">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: ghostAnimation,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                      />
                    </div>
                    <p className="mt-24 text-xl text-center">
                      There is no resource shared yet...
                    </p>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default AttachmentsModal;
