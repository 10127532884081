import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { 
  ChatBubbleLeftRightIcon,
  ChevronDoubleRightIcon,
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
  ChartPieIcon,
  Bars3Icon,
  XMarkIcon
} from "@heroicons/react/24/outline";

import LogoutIcon from "../../../sources/icons/LogoutIcon.js";


import CustomButton from '../../../components/CustomButton';
import { store } from "../../../reducers/store";
import { setAuthData } from "../../../reducers/auth.reducer";
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const navigate = useNavigate();
  const { userRole } = useSelector((state) => state.authReducer);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  }
  
  const onLogOut = () => {
    localStorage.removeItem("user-token");
    store.dispatch(
      setAuthData({
        userId: null,
        userToken: null,
        userName: null,
        firstName: null,
        lastName: null,
        email: null,
        userRole: null,
      })
    );
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="lg:min-h-dvh ">
      <div>
        <button
          className={`fixed z-10 lg:hidden top-6 left-6`}
          onClick={toggleSidebar}
        >
          {isOpen ? (
            <XMarkIcon className="h-8 w-8" />
          ) : (
            <Bars3Icon className="h-8 w-8 p-1" />
          )}
        </button>
      </div>
      <aside
        className={`fixed top-0 left-0 w-60 bg-[#f1f0f0] h-full transform  ${
          isOpen ? "translate-x-0 shadow-2xl shadow-black" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300 ease-in-out lg:relative flex flex-col justify-between lg:w-60  lg:border-r-2 lg:border-stone-300 lg:bg-white`}
      >
        <div>
          <h1 className="text-primary text-3xl font-semibold lg:ml-4 mt-6 mb-20 lg:text-left lg:mr-0 text-right mr-6">
            Kanka Bot
          </h1>
          <nav className="flex justify-center">
            <ul className="text-base font-medium text-stone-500">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                      isActive ? "text-black" : ""
                    }`
                  }
                  to="home"
                >
                  <HomeIcon className="h-6 w-6" />
                  <span className="pt-1">Home</span>
                </NavLink>
              </li>
              {userRole == "admin" && (
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                        isActive ? "text-black" : ""
                      }`
                    }
                    to="supervisor"
                  >
                    <UsersIcon className="h-6 w-6" />
                    <span className="pt-1">Supervisors</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                      isActive ? "text-black" : ""
                    }`
                  }
                  to="offender"
                >
                  <UserGroupIcon className="h-6 w-6" />
                  <span className="pt-1">Offenders</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                      isActive ? "text-black" : ""
                    }`
                  }
                  to="analytics"
                >
                  <ChartPieIcon className="h-6 w-6" />
                  <span className="pt-1">Analytics</span>
                </NavLink>
              </li>
              <ul className="ml-8 text-sm font-normal list-inside">
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                        isActive ? "text-black" : ""
                      }`
                    }
                    to="analytics/conversations"
                  >
                    <ChatBubbleLeftRightIcon className="h-5 w-5" />
                    <span>Conversations</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `flex flex-row gap-2 mb-1 py-1 hover:text-black ${
                        isActive ? "text-black" : ""
                      }`
                    }
                    to="analytics/conditions"
                  >
                    <ChevronDoubleRightIcon className="h-5 w-5" />
                    <span>Conditions</span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </nav>
        </div>
        <div className="flex justify-center p-1 mb-2">
          <CustomButton
            onClick={onLogOut}
            buttonSize="lg"
            buttonType="outline"
            buttonColor="danger"
            customStyle=""
          >
            <span className="flex flex-row gap-2 items-center justify-center">
              <LogoutIcon height={25} width={25} color={"black"} />
              Logout
            </span>
          </CustomButton>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
