import React from "react";

const CustomButton = ({
  onClick,
  buttonSize,
  buttonType,
  buttonColor,
  children,
  customStyle = "",
  disabled,
}) => {
  const size = ["full", "lg", "md", "sm"];
  const types = ["solid", "outline"];
  const colors = ["primary", "secondary", "warning", "danger"];

  buttonSize = size.includes(buttonSize) ? buttonSize : "md";
  buttonType = types.includes(buttonType) ? buttonType : "solid";
  buttonColor = colors.includes(buttonColor) ? buttonColor : "primary";

  const colorConfig = {
    primary: {
      borderColor: "border-[#47A18A]",
      bgColor: "bg-[#47A18A]",
      textColor: "text-[#47A18A]",
      hoverBg: "hover:bg-[#47A18A]",
    },
    secondary: {
      borderColor: "border-blue-500",
      bgColor: "bg-blue-500",
      textColor: "text-blue-500",
      hoverBg: "hover:bg-blue-500",
    },
    danger: {
      borderColor: "border-[#ef4444]",
      bgColor: "bg-[#ef4444]",
      textColor: "text-[#ef4444]",
      hoverBg: "hover:bg-[#ef4444]",
    },
    warning: {
      borderColor: "border-yellow-500",
      bgColor: "bg-yellow-500",
      textColor: "text-yellow-500",
      hoverBg: "hover:bg-yellow-500",
    },
  };

  return (
    <button
      type="submit"
      disabled={disabled}
      className={`
          ${buttonSize === "sm" ? "w-1/4 text-md " : " "}
          ${buttonSize === "md" ? "min-w-fit md:w-1/5 lg:w-1/6 text-md " : " "}
          ${buttonSize === "lg" ? "w-1/2 text-md " : " "}
          ${buttonSize === "full" ? "w-full text-md " : " "}
          ${
            buttonType === "outline"
              ? ` border ${colorConfig[buttonColor].borderColor} ${colorConfig[buttonColor].hoverBg} ${colorConfig[buttonColor].textColor} hover:text-white `
              : " "
          }
          ${
            buttonType === "solid"
              ? ` ${colorConfig[buttonColor].bgColor} text-white hover:${
                  colorConfig[buttonColor].textColor
                } ${
                  buttonColor === "primary"
                    ? disabled
                      ? ""
                      : " hover:bg-secondary"
                    : disabled
                    ? ""
                    : " hover:bg-primary"
                } `
              : " "
          }
          ${disabled ? "cursor-not-allowed opacity-50" : ""}
          font-bold py-2 px-2 items-center rounded whitespace-nowrap ${customStyle}`}
          onClick={onClick}

    >
      {children}
    </button>
  );
};

export default CustomButton;
