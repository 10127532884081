import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";

const TypingText = forwardRef(function RenderText(
  { text, delay, infinite, onCompleted, onContinueStatusChange },
  ref
) {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textChunks, setTextChunks] = useState([]);
  const [currentTextChunk, setCurrentTextChunk] = useState(0);
  const [utterance, setUtterance] = useState(null);
  const [voice, setVoice] = useState(null);
  const pitch = 1;
  const speedRate = 0.7;
  const [volume, setVolume] = useState(0);
  const [isBotTalking, setBotTalking] = useState(false);

  const chunkTextByPeriods = useCallback((text, maxLength) => {
    const chunks = [];
    let currentChunk = "";

    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];

    for (const sentence of sentences) {
      if (currentChunk.length + sentence.length <= maxLength) {
        currentChunk += sentence;
      } else {
        if (currentChunk) chunks.push(currentChunk.trim());
        currentChunk = sentence;
      }
    }

    if (currentChunk) chunks.push(currentChunk.trim());

    return chunks;
  }, []);

  useEffect(() => {
    const chunkedText = chunkTextByPeriods(text, 250);
    setTextChunks(chunkedText);
    setCurrentTextChunk(0);
    setCurrentIndex(0);
    setCurrentText("");

    initializeSpeechSynthesis();
  }, [text, chunkTextByPeriods]);

  const initializeSpeechSynthesis = () => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance();
    setUtterance(u);

    u.onend = function () {
      setBotTalking(false);
      setVolume(0); // Mute when speech ends
    };

    setTimeout(() => {
      const voices = synth.getVoices();
      setVoice(voices[1]);
    }, 300);
  };

  const continueRenderingLetters = useCallback((toggleSpeaker) => {
    cancelSpeech()
    setCurrentText("");
    setCurrentIndex(0);
    setCurrentTextChunk((prevChunk) => prevChunk + 1);
    initializeSpeechSynthesis();
    setTimeout(() => {
      if (toggleSpeaker) {
        startSpeech(currentTextChunk + 1, textChunks, voice);
      }
    }, 300);
  }, [currentTextChunk, textChunks, voice]);

  useImperativeHandle(ref, () => ({
    continueRenderingLetters,
    toggleVolume,
    restartSpeech,
    cancelSpeech
  }));

  const restartSpeech = () => {
    cancelSpeech();
    setVolume(1)
    const synth = window.speechSynthesis;
    utterance.text = textChunks[currentTextChunk];
    utterance.voice = voice;
    utterance.pitch = pitch;
    utterance.rate = speedRate;
    utterance.volume = 1;
    synth.speak(utterance);
    setBotTalking(true);
  }

  const toggleVolume = () => {
    if (volume === 0) {
      setVolume(1);
      if (!isBotTalking) {
        startSpeech();
      }
    } else {
      cancelSpeech();
      setBotTalking(false);
    }
  };

  const cancelSpeech = useCallback(() => {
    setVolume(0);
    window.speechSynthesis.cancel();
  }, []);

  const startSpeech = useCallback(
    (currentChunk = currentTextChunk, chunks = textChunks, currentVoice = voice) => {
      setVolume(1);
      const synth = window.speechSynthesis;
      utterance.text = chunks[currentChunk].slice(currentIndex);
      utterance.voice = currentVoice;
      utterance.pitch = pitch;
      utterance.rate = speedRate;
      utterance.volume = 1;
      synth.speak(utterance);
      setBotTalking(true);
    },
    [isBotTalking, currentIndex, currentTextChunk, utterance]
  );

  const renderLetterText = useCallback(() => {
    if (currentTextChunk >= textChunks.length) return;
    const currentChunk = textChunks[currentTextChunk];
    setCurrentText((prevText) => prevText + currentChunk[currentIndex]);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, [currentTextChunk, textChunks, currentIndex]);

  useEffect(() => {
    let timeout;

    if (textChunks.length === 0) return;

    const currentChunk = textChunks[currentTextChunk];
    if (!currentChunk) return;

    if (currentIndex < currentChunk.length) {
      timeout = setTimeout(renderLetterText, delay);
    } else if (currentTextChunk < textChunks.length - 1) {
      onContinueStatusChange();
      //cancelSpeech();
    } else if (infinite) {
      timeout = setTimeout(() => {
        setCurrentTextChunk(0);
        setCurrentIndex(0);
        setCurrentText("");
      }, delay);
    } else if (onCompleted) {
      onCompleted();
    }

    return () => clearTimeout(timeout);
  }, [
    currentIndex,
    currentTextChunk,
    delay,
    infinite,
    onCompleted,
    onContinueStatusChange,
    renderLetterText,
    textChunks,
  ]);

  return <span>{currentText}</span>;
});

export default TypingText;
