import { store } from "../reducers/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest } from "./makeRequest";

export async function saveToolForm(file, formName, navigate, location) {
  const appReducer = store.getState().appReducer;

  try {
    const formData = new FormData();
    formData.append("image", file, formName + ".png");
    formData.append("formName", formName);

    const conversationId = appReducer.currentConversation?.conversationId;
    formData.append("conversationId", conversationId);

    await makePostRequest({
      path: "/api/conversation/save-tool-form", body: formData, config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }, navigate, location});

  } catch (error) {
    toast.error("Error saving tool form...");
  }
}
