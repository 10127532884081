export function sanitizeAndParseJson(jsonLikeString) {
    // Remove newline characters and any spaces immediately around them within JSON string literals.
    const sanitizedString = jsonLikeString
        .replace(/\s*[\r\n]+\s*/g, "").replace("\"", '"'); // Remove newlines and spaces around them.


    try {
        const jsonObj = JSON.parse(sanitizedString);
        return jsonObj;
    } catch (error) {
        return null;
    }
}
