import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import CustomButton from "../../components/CustomButton";
import snarkdown from "snarkdown";
import Lottie from "react-lottie";
import redPulse from "../../sources/lotties/redPulse.json";
import doneAnimation from "../../sources/lotties/doneAnimation.json";
import loadingSpinner from "../../sources/lotties/loadingSpinner.json";
import { store } from "../../reducers/store";
import { useLocation, useNavigate } from "react-router-dom";
import { makePostRequest } from "../../utils/makeRequest";

const renderUnsafeHTML = (message) => {
  const html = snarkdown(message);
  return html.replace(/<a href/gi, '<a target="_blank" href');
};

const sendVideoToBackend = async (
  blob,
  currentConversationId,
  setUploadingStatus,
  hideVideoRecorder,
  navigate, 
  location
) => {
  setUploadingStatus("uploading");
  const videoFile = new File(
    [blob],
    `${store.getState().authReducer.userName}.mp4`,
    {
      type: "video/mp4",
    }
  );

  const formData = new FormData();
  formData.append("video", videoFile);
  formData.append("conversationId", currentConversationId);

  const response = await makePostRequest({
    path: "/api/conversation/upload-video-recording",
    body: formData,
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    navigate, location
  });

  if (!response.data.success) {
    throw new Error("Failed to upload video");
  }

  setUploadingStatus("done");
  setTimeout(() => {
    hideVideoRecorder();
    window.botpressWebChat.sendEvent({
      type: "proactive-trigger",
      channel: "web",
      payload: {
        text: "Recording video completed!",
      },
    });
  }, 2500);
};

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return (
    <video
      ref={videoRef}
      width={500}
      height={500}
      autoPlay
      controls
      style={{ borderRadius: 20, borderWidth: 8 }}
    />
  );
};

//eslint-disable-next-line
const VideoRecorder = forwardRef(function VideoRecorder({}, ref) {
  const [showRecordVideo, setRecordVideoStatus] = useState(false);
  const [uploadingVideoStatus, setUploadingStatus] = useState(null);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [videoRecorderData, setVideoRecorderData] = useState({
    title: "",
    description: "",
  });

  const showVideoRecorder = ({
    title,
    description,
    currentConversationId: currentConversation,
  }) => {
    setRecordVideoStatus(true);
    setVideoRecorderData({ title, description });
    setCurrentConversationId(currentConversation);
  };

  const hideVideoRecorder = () => {
    setRecordVideoStatus(false);
    setUploadingStatus(null);
    setCurrentConversationId(null);
    setVideoRecorderData({
      title: "",
      description: "",
    });
  };

  useImperativeHandle(ref, () => ({
    showVideoRecorder,
    hideVideoRecorder,
    uploadingVideoStatus
  }));

  const renderRecordVideo = ({ navigate, location }) => {
    if (showRecordVideo) {
      return (
        <div className="absolute flex-1 w-screen h-screen z-20 flex items-center justify-center">
          <div className="absolute bg-white z-50 w-3/4 md:w-2/3 p-12 rounded-xl flex opacity-100 flex-row">
            <div className="w-full">
              <ReactMediaRecorder
                video
                onStop={(blobUrl, blob) => {
                  sendVideoToBackend(
                    blob,
                    currentConversationId,
                    setUploadingStatus,
                    hideVideoRecorder,
                    navigate,
                    location
                  );
                }}
                render={({
                  status,
                  startRecording,
                  stopRecording,
                  previewStream,
                }) => (
                  <div className="w-full">
                    <div className="w-full min-h-96 flex flex-row flex-wrap justify-center">
                      {status === "recording" && (
                        <div className="w-full md:w-1/2">
                          <VideoPreview stream={previewStream} />
                        </div>
                      )}
                      <div className="w-full h-full md:w-1/2 px-12 flex-wrap flex overflow-y-scroll">
                        {status === "recording" && (
                          <div className="-ml-4 w-full flex flex-row items-center justify-start">
                            <div className="w-10 h-10">
                              <Lottie
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: redPulse,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                              />
                            </div>
                            <p className="mt-1">Recording</p>
                          </div>
                        )}
                        {!uploadingVideoStatus ? (
                          <>
                            <h3 className="text-2xl w-full font-bold">
                              {videoRecorderData.title}
                            </h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: renderUnsafeHTML(
                                  videoRecorderData.description
                                ),
                              }}
                            />
                          </>
                        ) : uploadingVideoStatus === "done" ? (
                          <div className="w-full h-full flex flex-col items-center justify-center">
                            <div className="w-auto h-80 mt-2">
                              <Lottie
                                options={{
                                  loop: false,
                                  autoplay: true,
                                  animationData: doneAnimation,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                              />
                            </div>
                            <p className="text-xl">Uploaded successfuly!</p>
                          </div>
                        ) : (
                          <div className="w-full h-full flex flex-col items-center justify-center">
                            <div className="w-40 h-40 mt-12">
                              <Lottie
                                options={{
                                  loop: true,
                                  autoplay: true,
                                  animationData: loadingSpinner,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                              />
                            </div>
                            <p className="mt-24 text-xl">
                              One moment, saving...
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {!uploadingVideoStatus && (
                      <div className="flex flex-row items-center justify-center">
                        {status === "recording" ? (
                          <CustomButton
                            onClick={() => {
                              stopRecording();
                            }}
                            buttonSize="lg"
                            buttonType="outline"
                            buttonColor="danger"
                            customStyle="m-2"
                          >
                            Stop Recording
                          </CustomButton>
                        ) : (
                          <div className="flex flex-col w-full items-center justify-center">
                            {!uploadingVideoStatus && (
                              <p className="font-bold text-lg text-blue-500 mb-4">
                                Click "Start Recording" when you are ready.
                              </p>
                            )}
                            <CustomButton
                              onClick={startRecording}
                              buttonSize="lg"
                              buttonType="solid"
                              buttonColor="primary"
                              customStyle="m-2"
                            >
                              Start Recording
                            </CustomButton>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="w-screen h-screen z-30 bg-zinc-800 opacity-80"></div>
        </div>
      );
    }
  };

  return <>{renderRecordVideo({ navigate: useNavigate(), location: useLocation()})}</>;
});

export default VideoRecorder;
