import { makeGetRequest } from './makeRequest';

export const fetchUserInfo = () => {
    return new Promise((resolve, reject) => {
        makeGetRequest({
          path: "/api/user/user-info",
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          }})
          .then(({ data }) => {
            return resolve(data)
          }).catch((err) => {
            return reject(err);
          });
    })
}