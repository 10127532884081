import axios from 'axios';
import { store } from '../reducers/store'
import { updateTokens, setAuthData } from "../reducers/auth.reducer";
import { baseUrl } from "./constants";
import { toast } from "react-toastify";

axios.interceptors.request.use((config) => {
const token = JSON.parse(localStorage.getItem("user-token"));
  if (token) {
    config.headers['authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response) => {
    if (response.success && response.data?.message) {
      toast(response.data.message, { type: response.data.success ? 'success' : 'error' });
    }
    if (response.data.success === false && response.data?.message) {
      if (
        response.data.message ===
        "You do not have authorization to access this route."
      ) {
        localStorage.removeItem("user-token");
        localStorage.removeItem("refresh-token");
        store.dispatch(
          setAuthData({
            userId: null,
            userToken: null,
            refreshToken: null,
            userName: null,
            firstName: null,
            lastName: null,
            email: null,
            userRole: null,
          })
        );
        window.location.href = "/login";
      }
    } else if (response.data.success === false && !response.data?.message) {
      toast("Something went wrong", { type: "error" });
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest?.sent) {
      try {
        originalRequest.sent = true;
        
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${baseUrl}/api/auth/refresh`, { headers: { "x-refresh-token": refreshToken } });
        const { authToken, refreshToken: newRefreshToken } = response.data;

        localStorage.setItem('user-token', authToken);
        localStorage.setItem('refresh-token', newRefreshToken);
        store.dispatch(updateTokens({ authToken, refreshToken: newRefreshToken }));

        originalRequest.headers['authorization'] = `${authToken}`;
        return axios(originalRequest);

      } catch (err) {
        if (err.response && err.response.status === 403) {
          localStorage.removeItem('user-token');
          localStorage.removeItem('refresh-token');
          store.dispatch(setAuthData({
            userId: null,
            userToken: null,
            refreshToken: null,
            userName: null,
            firstName: null,
            lastName: null,
            email: null,
            userRole: null,
          }));
        }
      } 
    } 
    if (error?.response?.data && error?.response?.data?.message) {
      console.log(error.response.data);
      toast(error.response.data.message, { type: 'error' });
    }
    return Promise.reject(error);
  }
);
