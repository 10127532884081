import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentConversation: null,
  currentPageLocation: null,
};

const appReducer = createSlice({
  name: "appReducer",
  initialState,
  reducers: {
    setCurrentLocation: (state, action) => {
      state.currentPageLocation = action.payload;
    },
    setCurrentConversation: (state, action) => {
      state.currentConversation = action.payload;
    }
  },
});

export const { setCurrentLocation, setCurrentConversation } =
  appReducer.actions;
export default appReducer.reducer;
