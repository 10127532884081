import Sidebar from "./components/Sidebar";
import { Outlet } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <div className="h-screen-dvh flex flex-col">
      <div className="flex flex-row h-full bg-stone-100">
        <Sidebar />
        <main className="flex grow">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
