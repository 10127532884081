import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import {
  Field, Label, Select,
  Popover, PopoverButton, PopoverPanel,
  Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, ComboboxButton
} from '@headlessui/react'
import {
  CalendarIcon,
  ChatBubbleOvalLeftIcon,
  ClockIcon,
  UserIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
  ChevronDownIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";

import { store } from "../../../reducers/store";
import { baseUrl } from "../../../utils/constants";
import DataGrid from '../components/DataGrid';
import CustomModal from '../../../components/CustomModal';
import CustomButton from '../../../components/CustomButton';
import CheckmarkIcon from "../../../sources/icons/CheckmarkIcon.svg";

const Home = () => {

  const [assignedOffenders, setAssignedOffenders] = useState([]);
  const [selectedOffender, setSelectedOffender] = useState({});
  
  const [showAssignToolModal, setShowAssignToolModal] = useState(false);
  const [treatmentTools, setTreatmentTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState({})
  const [query, setQuery] = useState('')

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  
  const [lastActiveConversations, setLastActiveConversations] = useState([]);
  const [otherOfficerMetrics, setOtherOfficerMetrics] = useState({ activeOffenders: null, totalOffenders: null });
  const [isRightContentVisible, setIsRightContentVisible] = useState(false);
  const [selectedAnalysisPeriod, setSelectedAnalysisPeriod] = useState('1y');

  const toggleRightSidebar = () => {
    setIsRightContentVisible(!isRightContentVisible);
  }

  const firstName = store.getState().authReducer.firstName;
  const lastName = store.getState().authReducer.lastName;

  
  const fetchAssignedOffenders = async () => {
    const { authReducer } = store.getState();
    return await axios.get(
      `${baseUrl}/api/user/get-assigned-offenders`,
      { headers: { "Content-Type": "application/json", authorization: authReducer.userToken, } }
    );
  };

  const computeAnalysisePeriodStartAndEnd = (analysisPeriod) => {
    let analysisPeriodStart, analysisPeriodEnd;
    analysisPeriodEnd = moment().format('YYYY-MM-DD');
    if (analysisPeriod === '3d') {
      analysisPeriodStart = moment().subtract(3, 'days').format('YYYY-MM-DD');
    } else if (analysisPeriod === '1w') {
      analysisPeriodStart = moment().subtract(1, 'week').format('YYYY-MM-DD');
    } else if (analysisPeriod === '1m') {
      analysisPeriodStart = moment().subtract(1, 'month').format('YYYY-MM-DD');
    } else if (analysisPeriod === '1y') {
      analysisPeriodStart = moment().subtract(1, 'year').format('YYYY-MM-DD');
    } else {
      analysisPeriodStart = moment().subtract(3, 'months').format('YYYY-MM-DD');
    }
    return { analysisPeriodStart, analysisPeriodEnd }
  }

  const updateAnalysisPeriod = (event) => {
    const analysisPeriod = event.target.value;
    setSelectedAnalysisPeriod(analysisPeriod);
  }

  const fetchTreatmentTools = async () => {
    const { authReducer } = store.getState();
    return await axios.get(
      `${baseUrl}/api/conversation/list-treatment-tools`,
      { headers: { "Content-Type": "application/json", authorization: authReducer.userToken, } }
    );
  }

  const assignTool = () => {
    const { authReducer } = store.getState();
    if (!title || !description || !selectedTool) {
      toast('Please fill all the details.', { type: "error" });
      return;
    }
    axios
      .post(
        `${baseUrl}/api/conversation/assign-a-tool`,
        { toolName: selectedTool.name, offenderId: selectedOffender.userId, conversationTitle: title, conversationDescription: description },
        { headers: { "Content-Type": "application/json", authorization: authReducer.userToken, } }
      )
      .then(res => res.data)
      .then(data => {
        if (data.success) {
          setShowAssignToolModal(false);
          setTitle('');
          setDescription('');
          setSelectedTool(treatmentTools[0]);
          toast("Assigned a tool successfully!", { type: "success" });
        }
      })
      .catch(res => {
        toast(res?.data?.message ?? 'Something went wrong', { type: "error" });
      })
  }

  useEffect(() => {
    const promises = [fetchAssignedOffenders(), fetchTreatmentTools()];
    Promise
      .all(promises)
      .then(results => {
        results[0]?.data?.success && setAssignedOffenders(results[0].data.offenders);
        results[0]?.data?.success &&
          setOtherOfficerMetrics({
            activeOffenders: results[0].data.offenders.length,
            totalOffenders: results[0].data.offenders.length,
          });
        results[1]?.data?.success && setTreatmentTools(results[1].data.data);
      })
      .catch((error) => {
        console.log(error);
        toast("something went wrong", { type: "error" });
      });
  }, []);

  useEffect(() => {
    async function fetchLastActiveConversations() {
      const { authReducer } = store.getState();
      const { analysisPeriodStart, analysisPeriodEnd } = computeAnalysisePeriodStartAndEnd(selectedAnalysisPeriod);
      return await axios.get(
        `${baseUrl}/api/user/get-latest-conversations-for-officer`,
        {
          headers: { "Content-Type": "application/json", authorization: authReducer.userToken, },
          params: {
            analysisPeriodStart,
            analysisPeriodEnd,
            limit: 30
          }
        }
      );
    };

    fetchLastActiveConversations()
      .then(res => {
        res?.data?.success && setLastActiveConversations(res.data.latestConversationDetails);
      })
      .catch(error => {
        console.log(error)
        toast("something went wrong", { type: "error" });
      });
  }, [selectedAnalysisPeriod])

  useEffect(() => {
    setSelectedTool(treatmentTools[0])
  }, [treatmentTools])

  const columns = [
    { label: "Conversation", accessor: "conversationTitle", icon: ChatBubbleOvalLeftIcon, fillIcon: true, showIconBackground: true },
    { label: "Name", accessor: "fullName" },
    {
      label: "Status", accessor: "status",
      icon: ({ className }) => ( <span className={`${className}`}></span> ),
      fillIcon: false, showIconBackground: true, backgroundPadding: 'p-1'
    },
    { label: "Last Updated", accessor: "updatedAt", icon: ClockIcon, fillIcon: false, showIconBackground: false }
  ]

  const rows = lastActiveConversations.map( conversation => ({
    ...conversation, status: conversation.isActive ? 'In Progress' : 'Completed',
    fullName: `${conversation.firstName} ${conversation.lastName}`,
    updatedAt: moment(conversation.updatedAt).fromNow().toString()
  }));

  const filteredTools = 
    query === ''
      ? treatmentTools
      : treatmentTools.filter((tool) => {
          return tool.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <div id="container" className="flex flex-col xl:flex-row min-w-full min-h-dvh xl:justify-between">
      <div className='grow'>
        <div className="heading flex justify-between m-6">
          <div id="title" className='mt-10 lg:mt-0'>
            <p className="text-3xl font-bold">Hello, {firstName}</p>
            <p className='text-stone-500'>Here is an overview about what happened today</p>
          </div>
          <div className='flex gap-2 max-h-fit'>
            <p className='max-h-fit pt-1'>{moment().format('D MMM, YYYY')}</p>
            <CalendarIcon className="h-7 w-7 rounded-full bg-[#f1f0f0] p-1" />
            <button className={`${isRightContentVisible ? 'fixed right-6 top-6' : 'relative'} z-10 xl:hidden max-h-fit flex`} onClick={toggleRightSidebar}>
              { isRightContentVisible ? <XMarkIcon className='h-7 w-7 p-1 rounded-full bg-[#f1f0f0]' /> : <UserIcon className='h-7 w-7 p-1 rounded-full bg-[#f1f0f0]' /> }
            </button>
          </div>
        </div>      
        <section id="main-content" className='xl:basis-3/4 px-6 xl:py-6 grow'>

          <div id="last-active-conversations">
            <div className="table-header flex justify-between mb-3">
              <p className="font-bold pl-2">Last Active Conversations</p>
              <Field>
                <Label>Analysis Period: </Label>
                <Select name="analysisPeriod" aria-label="Analysis Period" defaultValue={selectedAnalysisPeriod} onChange={updateAnalysisPeriod} className={`border rounded-lg py-2 px-1 data-[hover]:shadow data-[focus]:bg-blue-100`}>
                  <option value="3d">3 Days</option>
                  <option value="1w">1 Week</option>
                  <option value="1m">1 Month</option>
                </Select>
              </Field>
            </div>

            <div className="container min-w-full grow">
              <DataGrid showHeader={false} columns={columns} rows={rows}  />
            </div>
          </div>
        </section>
      </div>
      <section id="right-content" className={`fixed top-0 right-0 xl:relative xl:basis-1/4 p-6 flex flex-col h-screen overflow-hidden w-64 transform bg-white ${isRightContentVisible ? 'translate-x-0 shadow-2xl shadow-black' : 'translate-x-full'} xl:translate-x-0 transition-transform duration-300 ease-in-out`}>
        <div id="profile-details" className="min-w-full bg-[#f1f0f0] py-3 xl:p-6 rounded-xl">
          <div className='flex flex-col min-w-full'>
            <div id="logo-and-name" className='flex flex-col items-center'>
                <div className='p-2 mb-1 border-4 border-white rounded-full'><UserIcon className='h-8 w-8' /></div>
                <p>{firstName} {lastName}</p>
                <p className="text-xs text-stone-500">(Officer)</p>
            </div>
            <div id="offender-metrics" className='mt-2 flex'>
              <div id="active-offenders" className='basis-1/2 p-3'>
                <p className='text-sm'>Active Offenders</p>
                <p className='text-2xl'>{otherOfficerMetrics.activeOffenders}</p>
              </div>
              <div id="total-offenders" className='basis-1/2 p-3 text-right'>
                <p className='text-sm'>Assigned Offenders</p>
                <p className='text-2xl'>{otherOfficerMetrics.totalOffenders}</p>
              </div>
            </div>
          </div>
        </div>
        <div id="assigned-offenders-list" className='mt-6 flex flex-col flex-grow overflow-hidden'>
          <div className="offenders-list-header flex justify-between mb-3">
            <p className='text-stone-300'>----</p>
            <p>Assigned Offenders</p>
            <p className='text-stone-300'>----</p>
          </div>
          <Popover>
            <ul className='overflow-y-scroll flex-grow'>
              {assignedOffenders.map(offender => {
                return (
                  <li key={offender.userId} className='flex justify-between py-3'>
                    <div className="flex grow gap-2">
                      <UserIcon className='h-6 w-6' />
                      <p className='border-b-2 grow pb-3'>{offender.firstName} {offender.lastName}</p>
                    </div>
                    <PopoverButton onClick={() => { setSelectedOffender(offender) }} className='pb-3 h-6 w-6 rounded-full data-[focus]:bg-[#f1f0f0] data-[active]:bg-[#f1f0f0]'>
                      <EllipsisHorizontalIcon className='h-6 w-6' />
                    </PopoverButton>
                  </li>
                )
              })}
            </ul>
            <PopoverPanel
              transition
              anchor="bottom end"
              className="divide-y divide-white/5 rounded-lg bg-[#f1f0f0] text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 p-2"
            >
              <div className="">
                <button onClick={() => setShowAssignToolModal(true)}>Assign Tool</button>
              </div>
            </PopoverPanel>
          </Popover>
        </div>
        <CustomModal
          isOpen={showAssignToolModal}
          onClose={() => setShowAssignToolModal(false)}
          titleStyle={"w-full flex items-center justify-left mb-6"}
          modalStyle={"w-full md:w-2/3 lg:w-1/3 sm:h-1/2 md:h-min"}
          title={"Assign a Tool"}
        >
          <div className='h-full w-full flex flex-col'>
            <p className='flex gap-2 mb-2 self-end'>
              <UserIcon className='h-6 w-6' />
              <span className='h-6 pt-0.5'>{selectedOffender.firstName} {selectedOffender.lastName}</span>
            </p>

            <input
              type="text"
              placeholder="My new tool"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full border rounded-md p-3 mb-4"
              style={{ fontSize: "14px" }}
            />

            <textarea
              placeholder="My new tool that helps me for..."
              value={description}
              maxLength={150}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full border rounded-md p-3 mb-4 h-32"
              style={{ fontSize: "14px" }}
              draggable="false"
            />

            <Field className={'flex gap-2 w-full mb-4'}>
              <Combobox value={selectedTool} onChange={setSelectedTool} onClose={() => setQuery('')}>
                <div className="relative w-full">
                  <ComboboxInput
                    aria-label="Tool"
                    displayValue={(tool) => tool?.name}
                    onChange={(event) => setQuery(event.target.value)}
                    className='w-full rounded-lg border-none bg-[#f1f0f0] py-1.5 pr-8 pl-3 text-sm/6 text-black
                      focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                  />
                  <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                    <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white" />
                  </ComboboxButton>
                </div>
                <ComboboxOptions
                  anchor="bottom"
                  className="
                    w-[var(--input-width)] rounded-lg border border-white/5 bg-[#f1f0f0] p-1 [--anchor-gap:var(--spacing-1)] empty:invisible
                    transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0
                  "
                >
                  {filteredTools.map((tool) => (
                    <ComboboxOption key={tool.flowId} value={tool} className="data-[focus]:bg-blue-100 py-2 border-b text-sm rounded-lg px-2">
                      {tool.name}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              </Combobox>
            </Field>

            <div className=''>
              <div className="flex justify-end">
                <CustomButton
                  onClick={assignTool}
                  buttonSize="md"
                  buttonType="solid"
                  buttonColor="primary"
                  customStyle=""
                >
                  <span className="flex flex-row gap-2 items-center justify-center text-md">
                    <span className="pt-1.5">Assign</span>
                    <img
                      src={CheckmarkIcon}
                      alt="Search Icon"
                      height={20}
                      width={20}
                      className=""
                    />
                  </span>
                </CustomButton>
              </div>
            </div>
          </div>
        </CustomModal>
      </section>
    </div>
  );
};

export default Home;
