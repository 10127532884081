import React, { Fragment, useState, useEffect } from "react";
import { store } from "../../reducers/store";
import SearchIcon from "../../sources/icons/SearchIcon.svg";
import PlusIcon from "../../sources/icons/PlusIcon.js";
import ChevronDown from "../../sources/icons/ChevronDown.js";
import MessageBubble from "../../sources/icons/MessageBubble.svg";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import NewConversationModal from "../home/components/NewConversationModal";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuthData } from "../../reducers/auth.reducer.js";
import UserIcon from "../../sources/icons/UserIcon.svg";
import ViewTool from "./Components/ViewTool.jsx";
import ProfileModal from "../../components/ProfileModal.jsx";
import { makeGetRequest, makePatchRequest } from "../../utils/makeRequest.js";

const DashboardPage = () => {
  const [activeToolsVisible, setActiveToolsVisible] = useState(true);
  const [allToolsVisible, setAllToolsVisible] = useState(true);
  const [compltedToolsVisible, setCompletedToolsVisible] = useState(true);
  const authReducer = store.getState().authReducer;
  const [activeTools, setActiveTools] = useState([]);
  const [allTools, setAllTools] = useState([]);
  const [completedTools, setCompletedTools] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedActiveFilter, setSelectedActiveFilter] = useState("All");
  const [selectedCompletedFilter, setSelectedCompletedFilter] = useState("All");
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isViewToolOpen, setIsViewToolOpen] = useState(false);
  const [toolImage, setToolImage] = useState(null);
  const [selectedToolId, setSelectedToolId] = useState(null);

  const openUserProfile = () => {
    setIsProfileOpen(true);
  };

  const closeUserProfile = () => {
    setIsProfileOpen(false);
  };

  const onLogOut = () => {
    localStorage.removeItem("user-token");
    store.dispatch(
      setAuthData({
        userId: null,
        userToken: null,
        userName: null,
        firstName: null,
        lastName: null,
        email: null,
        userRole: null,
      })
    );
    const chatBot = document.getElementById("bp-web-widget");
    if (chatBot) {
      chatBot.remove();
    }
    localStorage.clear();
    navigate("/login");
  };

  const [isNewConversationModalOpen, setIsNewConversationModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCreateConversation = (conversationId) => {
    handleCloseNewConversationModal();
    if (conversationId) {
      setTimeout(() => {
        navigate(`/?conversationId=${conversationId}&newConversation=true`);
      }, 1000);
    } else {
      console.error("No conversationId received");
    }
  };

  const handleOpenNewConversationModal = () => {
    setIsNewConversationModalOpen(true);
  };

  const handleCloseNewConversationModal = () => {
    setIsNewConversationModalOpen(false);
  };

  const handleSearchIconClick = () => {
    setIsSearchOpen(!isSearchOpen);
    setSearchQuery("");
  };

  const deleteTool = async (e, conversationId) => {
    e.stopPropagation();
    const response = await makePatchRequest({
      path: `api/conversation/delete-conversation`,
      body: { conversationId },
      config: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      navigate, location
    });

    if (response.status === 200 && response.data.success) {
      setActiveTools((prevTools) =>
        prevTools.filter((tool) => tool.bpConversationId !== conversationId)
      );
      setAllTools((prevTools) =>
        prevTools.filter((tool) => tool.bpConversationId !== conversationId)
      );
    }
  };

  const getToolAbbreviation = (toolName) => {
    switch (toolName) {
      case "Cost Benefit Analysis":
        return "CBA";
      case "Behavior Chain":
        return "BC";
      case "Problem Solving":
        return "PS";
      case "Social Skills":
        return "SS";
      default:
        return toolName;
    }
  };

  const fetchActiveTools = async () => {
    const response = await makeGetRequest({
      path: "/api/conversation/active-tools",
      config: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      navigate, location
    });
    return response.data.success ? response.data.data : [];
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const fetchToolImage = async (conversationId) => {
    const response = await makeGetRequest({
      path: `/api/conversation/view-tool`,
      config: {
        params: { conversationId },
        headers: {
          "Content-Type": "application/json",
        },
      },
      navigate, location
    });

    if (response.data.success && response.data.data.imageUrls.length > 0) {
      setToolImage(response.data.data.imageUrls[0]);
    } else {
      setToolImage(null);
    }
  };

  const getTools = async () => {
    try {
      const { activeTools, allTools, completedTools } =
        await fetchActiveTools();
      setActiveTools(activeTools);

      setAllTools(allTools);

      setCompletedTools(completedTools);
    } catch (error) {
      console.error("Error fetching tools:", error);
    }
  };

  useEffect(() => {
    getTools();
    // eslint-disable-next-line
  }, []);

  const filteredActiveTools =
    searchQuery.length > 0
      ? activeTools.filter(
          (tool) =>
            tool.conversationTitle
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            tool.conversationDescription
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        )
      : activeTools;

  const filteredAllTools =
    searchQuery.length > 0
      ? allTools.filter(
          (tool) =>
            tool.conversationTitle
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            tool.conversationDescription
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        )
      : allTools;

  const filteredCompletedTools =
    searchQuery.length > 0
      ? completedTools.filter(
          (tool) =>
            tool.conversationTitle
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            tool.conversationDescription
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        )
      : completedTools;

  const compltedToolsToDisplay =
    selectedCompletedFilter === "All"
      ? filteredCompletedTools
      : filteredCompletedTools.filter(
          (tool) => tool.toolName === selectedCompletedFilter
        );

  const toolsToDisplay =
    selectedActiveFilter === "All"
      ? filteredActiveTools
      : filteredActiveTools.filter(
          (tool) => tool.toolName === selectedActiveFilter
        );

  const allToolsToDisplay =
    selectedFilter === "All"
      ? filteredAllTools
      : filteredAllTools.filter((tool) => tool.toolName === selectedFilter);

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-white ">
        {isNewConversationModalOpen && (
          <NewConversationModal
            isOpen={isNewConversationModalOpen}
            closeModal={() => setIsNewConversationModalOpen(false)}
            onCreateConversation={handleCreateConversation}
          />
        )}
        {isViewToolOpen && (
          <ViewTool
            conversationId={selectedToolId}
            imageUrl={toolImage}
            onClose={() => {
              setIsViewToolOpen(false);
              setToolImage(null);
            }}
          />
        )}
        <div className="absolute top-6 right-6 md:w-3/12 lg:w-1/12 w-3/12 xl:w-1/12 py-2 pr-2 bg-[#6B7DBB] shadow-md rounded-lg flex flex-row justify-start items-center z-10">
          <div className="w-12 h-12 absolute rounded-full bg-[#6B7DBB] -ml-3 flex items-center justify-center pb-1 shadow-md">
            <img
              src={UserIcon}
              alt="User Icon"
              height={24}
              width={24}
              color="#ffff"
            />
          </div>
          <div className="flex ml-9 items-center w-full justify-between text-white">
            <Menu as="div" className="relative w-full inline-block text-left">
              <div>
                <Menu.Button className="flex justify-between w-full items-center text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  {authReducer?.userName.charAt(0).toUpperCase() +
                    authReducer?.userName.slice(1)}
                  <ChevronDown
                    alt="Menu Icon"
                    height={16}
                    width={16}
                    strokeColor="white"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-4 w-56 origin-top-right divide-y divide-gray-200 px-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={openUserProfile}
                          className={`${
                            active ? "bg-[#6B7DBB] text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Account
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => onLogOut()}
                          className={`${
                            active
                              ? "bg-red-500 text-white"
                              : "bg-[#6B7DBB] text-white"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <ProfileModal
              isOpen={isProfileOpen}
              openModal={openUserProfile}
              closeModal={closeUserProfile}
            />
          </div>
        </div>
        <div className="flex flex-col py-36 w-28 h-full px-2 bg-gray-100 gap-8">
          <div className="flex w-full items-center justify-center flex-col border-r-4 border-b-4 border-blue-300 p-2 rounded-md">
            <div className="flex items-center w-full md:justify-center justify-start">
              <img
                src={MessageBubble}
                alt="Search Icon"
                height={28}
                width={28}
              />
            </div>
            <div className="flex flex-col mt-2">
              <div className={`text-sm font-semibold`}>My tools</div>
            </div>
          </div>
        </div>

        <div className="bg-[#F9FAFC] border-x-2 border-gray-300 overflow-y-auto lg:w-3/5 md:w-5/6 h-full">
          <div className="w-full relative p-6 pt-24 lg:pt-6 flex justify-between h-1/8">
            <div className="text-[40px] w-4/5 text-[#47A18A] relative  items-center flex font-bold">
              Welcome {authReducer?.firstName}!
            </div>
            <div className="flex relative gap-12 mt-4 md:mt-0 flex-row justify-end w-1/6">
              <div className="h-8 w-8">
                <img
                  src={SearchIcon}
                  alt="Search"
                  height={26}
                  width={26}
                  onClick={handleSearchIconClick}
                  className="cursor-pointer"
                />
              </div>
              <div onClick={handleOpenNewConversationModal}>
                <PlusIcon
                  height={30}
                  width={30}
                  strokeColor="black"
                  className="cursor-pointer stroke-blue-300 text-blue-300"
                />
              </div>
            </div>
          </div>
          {isSearchOpen && (
            <div className="w-full flex justify-center p-2 relative">
              <input
                type="text"
                value={searchQuery}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchChange(e);
                  }
                }}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="border rounded w-4/5 p-2 transition-all duration-300 ease-in-out"
                placeholder="Search..."
              />
            </div>
          )}
          <div className="w-full relative flex h-1/12">
            <div className="text-[20px] justify-start flex text-black font-bold p-2 relative left-5 w-5/6">
              Tools in Progress
            </div>
            <div className="w-1/6 cursor-pointer flex gap-6 justify-end items-start p-2 text-black">
              <Menu as="div" className="relative">
                <MenuButton className="bg-white border border-gray-200 text-black px-2 py-1 rounded-md">
                  Filter
                </MenuButton>
                <MenuItems className="w-52 absolute z-10 rounded-md bg-white border divide-y divide-gray-200 transition duration-100 ease-in-out bottom-0 transform translate-y-full">
                  {[
                    "All",
                    "Cost Benefit Analysis",
                    "Behavior Chain",
                    "Problem Solving",
                    "Social Skills",
                  ].map((filter) => (
                    <MenuItem key={filter}>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-[#6B7DBB] text-white"
                              : selectedActiveFilter === filter
                              ? "bg-[#6B7DBB]  text-white"
                              : "bg-white"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={() => setSelectedActiveFilter(filter)}
                        >
                          {filter}
                        </button>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
              <div
                onClick={() => {
                  setActiveToolsVisible(!activeToolsVisible);
                }}
                className="text-gray-00"
              >
                <div
                  className={`transition-transform duration-300 mt-1 ${
                    activeToolsVisible ? "" : "rotate-180"
                  }`}
                >
                  <ChevronDown height={16} width={16} strokeColor="black" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[2px] bg-gray-400 opacity-50"></div>
          <div
            className={`transition-all duration-500 ease-in-out w-full relative overflow-hidden ${
              activeToolsVisible ? "max-h-full" : "max-h-0 "
            } p-2 grid grid-cols-3 md:grid-cols-6  xl:grid-cols-8  gap-y-4 m-2 justify-items-center`}
          >
            {toolsToDisplay.length === 0 ? (
              <div className="col-span-full flex items-center justify-center h-24 text-gray-500">
                No Active Tools
              </div>
            ) : (
              toolsToDisplay.map((tool, index) => (
                <div
                  key={index}
                  className="flex relative hover:text-gray-700 group"
                >
                  <div
                    onClick={() => {
                      navigate("/?conversationId=" + tool.bpConversationId);
                    }}
                    className="w-full  m-1 cursor-pointer flex-col  flex items-center justify-center h-full"
                  >
                    <div className="w-[5rem]  h-[4.5rem] rounded-md opacity-50 font-bold text-blue-800 flex items-center text-2xl justify-center bg-blue-200">
                      {getToolAbbreviation(tool.toolName)}
                    </div>
                    <div className="text-xs max-w-[6rem] max-h-4 truncate text-black font-bold mt-1">
                      {tool.conversationTitle}
                    </div>
                    <div className="text-xs w-24 text-center h-8 max-h-10  text-black description-text mt-1 line-clamp-2">
                      {tool.conversationDescription}
                    </div>
                  </div>
                  <div className="flex items-end z-50  group-hover:cursor-pointer ">
                    <Menu>
                      <MenuButton className=" relative bottom-3 right-0 w-full invisible group-hover:visible">
                        <div className="group cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                            />
                          </svg>
                        </div>
                      </MenuButton>
                      <MenuItems className="w-52 absolute rounded-md z-50 bg-white border divide-y divide-gray-200 overflow-visible transition duration-100 ease-in-out mb-4 transform -translate-y">
                        {" "}
                        <MenuItem>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                navigate(
                                  "/?conversationId=" + tool.bpConversationId
                                );
                              }}
                              className={`${
                                active ? "bg-[#6B7DBB] text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Go to tool
                            </button>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? "bg-[#6B7DBB] text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Update
                            </button>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {({ active }) => (
                            <button
                              onClick={(e) =>
                                deleteTool(e, tool.bpConversationId)
                              }
                              className={`${
                                active ? "bg-red-500 text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Delete
                            </button>
                          )}
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="w-full relative flex h-1/12">
            <div className="text-[20px] justify-start flex text-black font-bold p-2 relative left-5 w-5/6">
              Completed Tools
            </div>
            <div className="w-1/6 cursor-pointer flex gap-6 justify-end items-start p-2 text-black">
              <Menu as="div" className="relative">
                <MenuButton className="bg-white border border-gray-200 text-black px-2 py-1 rounded-md">
                  Filter
                </MenuButton>
                <MenuItems className="w-52 absolute z-10 rounded-md bg-white border divide-y divide-gray-200 transition duration-100 ease-in-out bottom-0 transform translate-y-full">
                  {[
                    "All",
                    "Cost Benefit Analysis",
                    "Behavior Chain",
                    "Problem Solving",
                    "Social Skills",
                  ].map((filter) => (
                    <MenuItem key={filter}>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-[#6B7DBB] text-white"
                              : selectedCompletedFilter === filter
                              ? "bg-[#6B7DBB]  text-white"
                              : "bg-white"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={() => setSelectedCompletedFilter(filter)}
                        >
                          {filter}
                        </button>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
              <div
                onClick={() => {
                  setCompletedToolsVisible(!compltedToolsVisible);
                }}
                className="text-gray-00"
              >
                <div
                  className={`transition-transform duration-300 mt-1 ${
                    compltedToolsVisible ? "" : "rotate-180"
                  }`}
                >
                  <ChevronDown height={16} width={16} strokeColor="black" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[2px] bg-gray-400 opacity-50"></div>
          <div
            className={`transition-all duration-500 ease-in-out w-full relative overflow-hidden  ${
              compltedToolsVisible ? "max-h-[764px] " : "max-h-0 "
            } p-2 grid  grid-cols-3 md:grid-cols-6 xl:grid-cols-8  gap-y-4 m-2 justify-items-center`}
          >
            {compltedToolsToDisplay.length === 0 ? (
              <div className="col-span-full flex items-center justify-center h-24 text-gray-500">
                No Completed Tools
              </div>
            ) : (
              compltedToolsToDisplay.map((tool, index) => (
                <div
                  key={index}
                  className="flex relative hover:text-gray-700 group"
                >
                  <div
                    onClick={() => {
                      setSelectedToolId(tool.bpConversationId);
                      fetchToolImage(tool.bpConversationId);
                      setIsViewToolOpen(true);
                    }}
                    className="w-full m-1 cursor-pointer flex-col  flex items-center justify-center h-full"
                  >
                    <div className="w-[5rem]  h-[4.5rem] rounded-md opacity-50 font-bold text-blue-800 flex items-center text-2xl justify-center bg-blue-200">
                      {getToolAbbreviation(tool.toolName)}
                    </div>
                    <div className="text-xs max-w-[6rem] max-h-4 truncate text-black font-bold mt-1">
                      {tool.conversationTitle}
                    </div>
                    <div className="text-xs w-24 text-center h-8 max-h-10  text-black description-text mt-1 line-clamp-2">
                      {tool.conversationDescription}
                    </div>
                  </div>
                  <div className="flex items-end relative  group-hover:cursor-pointer ">
                    <Menu>
                      <MenuButton className="relative bottom-3 right-0 w-full invisible group-hover:visible">
                        <div className="group cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                            />
                          </svg>
                        </div>
                      </MenuButton>
                      <MenuItems className="w-52 absolute z-10 rounded-md bg-white border divide-y divide-gray-200 visible transition duration-100 ease-in-out -mt-14 transform -translate-y">
                        {" "}
                        <MenuItem>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                navigate(
                                  "/?conversationId=" + tool.bpConversationId
                                );
                              }}
                              className={`${
                                active ? "bg-[#6B7DBB] text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Go to tool
                            </button>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? "bg-[#6B7DBB] text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Update
                            </button>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {({ active }) => (
                            <button
                              onClick={(e) =>
                                deleteTool(e, tool.bpConversationId)
                              }
                              className={`${
                                active ? "bg-red-500 text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Delete
                            </button>
                          )}
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="w-full relative flex h-1/12">
            <div className="text-[20px] justify-start flex text-black font-bold p-2 relative left-5 w-5/6">
              All Tools
            </div>
            <div className="w-1/6 cursor-pointer flex gap-6 justify-end items-start p-2 text-black">
              <Menu as="div" className="relative">
                <MenuButton className="bg-white border border-gray-200 text-black px-2 py-1 rounded-md">
                  Filter
                </MenuButton>
                <MenuItems className="w-52 absolute z-10 rounded-md bg-white border divide-y divide-gray-200 transition duration-100 ease-in-out bottom-0 transform translate-y-full">
                  {[
                    "All",
                    "Cost Benefit Analysis",
                    "Behavior Chain",
                    "Problem Solving",
                    "Social Skills",
                  ].map((filter) => (
                    <MenuItem key={filter}>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-[#6B7DBB] text-white"
                              : selectedFilter === filter
                              ? "bg-[#6B7DBB]  text-white"
                              : "bg-white"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={() => setSelectedFilter(filter)}
                        >
                          {filter}
                        </button>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
              <div
                onClick={() => {
                  setAllToolsVisible(!allToolsVisible);
                }}
              >
                <div
                  className={`transition-transform mt-1 duration-300  ${
                    allToolsVisible ? "" : "rotate-180"
                  }`}
                >
                  <ChevronDown height={16} width={16} strokeColor="black" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[2px] bg-gray-400 opacity-50"></div>
          <div
            className={`transition-all duration-500 ease-in-out w-full relative overflow-y-hidden ${
              allToolsVisible ? "max-h-[764px] " : "max-h-0"
            } p-2 grid  grid-cols-3 md:grid-cols-6 xl:grid-cols-8  gap-y-4 m-2 justify-items-center`}
          >
            {allToolsToDisplay.length === 0 ? (
              <div className="col-span-full flex items-center justify-center h-24 text-gray-500">
                No Tools
              </div>
            ) : (
              allToolsToDisplay.map((tool, index) => (
                <div
                  key={index}
                  className="flex cursor-pointer relative hover:text-gray-700 group"
                >
                  <div
                    onClick={() => {
                      navigate("/?conversationId=" + tool.bpConversationId);
                    }}
                    className="w-full m-1 flex-col flex items-center justify-center h-full"
                  >
                    <div className="w-[5rem]  h-[4.5rem] rounded-md opacity-50 font-bold text-blue-800 flex items-center text-lg justify-center bg-blue-200">
                      {getToolAbbreviation(tool.toolName)}
                    </div>
                    <div className="text-xs max-w-[6rem] max-h-4 truncate text-black font-bold mt-1">
                      {tool.conversationTitle}
                    </div>
                    <div className="text-xs w-24 text-center h-8 max-h-10  text-black description-text mt-1 line-clamp-2">
                      {tool.conversationDescription}
                    </div>
                  </div>
                  <div className="flex items-end relative right-2 invisible group-hover:cursor-pointer ">
                    <Menu
                      as="div"
                      className="relative w-full inline-block text-left"
                    >
                      <div>
                        <MenuButton className="relative bottom-3 right-0 w-full invisible group-hover:visible">
                          <div className="group cursor-pointer">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                              />
                            </svg>
                          </div>
                        </MenuButton>
                      </div>
                      <MenuItems className="w-52 absolute z-10 rounded-md bg-white border divide-y divide-gray-200 visible transition duration-100 ease-in-out -mt-28 transform -translate-y">
                        {" "}
                        <MenuItem>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                navigate(
                                  "/?conversationId=" + tool.bpConversationId
                                );
                              }}
                              className={`${
                                active ? "bg-[#6B7DBB] text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Go to tool
                            </button>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {({ active }) => (
                            <button
                              className={`${
                                active ? "bg-[#6B7DBB] text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Update
                            </button>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {({ active }) => (
                            <button
                              onClick={(e) =>
                                deleteTool(e, tool.bpConversationId)
                              }
                              className={`${
                                active ? "bg-red-500 text-white" : "bg-white"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              Delete
                            </button>
                          )}
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
