import React, { useRef, useEffect, useState } from 'react';

const VideoThumbnail = ({ src, alt }) => {
  const videoRef = useRef(null);
  const [thumbnail, setThumbnail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const generateThumbnail = async () => {
      if (!videoRef.current) return;

      const video = videoRef.current;

      try {
        // Wait for metadata to load
        await new Promise((resolve) => {
          video.addEventListener('loadedmetadata', resolve, { once: true });
          video.load();
        });

        // Seek to 1 second or 25% of the video, whichever is less
        const seekTime = Math.min(1, video.duration * 0.25);
        video.currentTime = seekTime;

        // Wait for seeking to complete
        await new Promise((resolve) => {
          video.addEventListener('seeked', resolve, { once: true });
        });

        // Generate thumbnail
        const canvas = document.createElement('canvas');
        canvas.width = 320;  // Set a fixed width
        canvas.height = 180; // Set a fixed height (16:9 aspect ratio)
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        
        if (isMounted) {
          setThumbnail(canvas.toDataURL());
          setLoading(false);
        }
      } catch (error) {
        console.error('Error generating thumbnail:', error);
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    generateThumbnail();

    return () => {
      isMounted = false;
    };
  }, [src]);

  return (
    <>
      <video 
        ref={videoRef} 
        src={src} 
        style={{ display: 'none' }} 
        preload="metadata"
        crossOrigin="anonymous"
      />
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">Loading...</div>
      ) : thumbnail ? (
        <img src={thumbnail} alt={alt} className="w-full h-full object-contain" />
      ) : (
        <div className="w-full h-full flex items-center justify-center">Failed to load</div>
      )}
    </>
  );
};

export default VideoThumbnail;