import CustomButton from "../../components/CustomButton";
import SocialSkills from "../../sources/images/Social_Skills.png";
import html2canvas from "html2canvas";
import React, { useState, useEffect, useCallback } from "react";
import { store } from "../../reducers/store";
import { saveToolForm } from "../../utils/saveToolForm";
import Speaker from "../../sources/icons/Speaker";
import RefreshIcon from "../../sources/icons/RefreshIcon";
import MuteSpeaker from "../../sources/icons/MuteSpeaker";
import { useLocation, useNavigate } from "react-router-dom";

const SocialSkillForm = ({
  setShowSocialSkillForm,
  socialSkillFormData,
  closeModal,
}) => {
  const [downloadedImage, setDownloadedImage] = useState(null);
  const authReducer = store.getState().authReducer;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const [toggleSpeaker, setToggleSpeaker] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [voice, setVoice] = useState(null);
  const pitch = 1;
  const speedRate = 0.7;
  const [volume, setVolume] = useState(0);
  const [isBotTalking, setBotTalking] = useState(false);

  const initializeSpeechSynthesis = () => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance();
    setUtterance(u);

    u.onend = function () {
      setBotTalking(false);
      setVolume(0); // Mute when speech ends
      setToggleSpeaker(true);
    };

    setTimeout(() => {
      const voices = synth.getVoices();
      setVoice(voices[1]);
    }, 300);
  };

  const cancelSpeech = useCallback(() => {
    setVolume(0);
    window.speechSynthesis.cancel();
  }, []);

  const toggleVolume = () => {
    if (volume === 0) {
      setVolume(1);
      if (!isBotTalking) {
        startSpeech();
      }
    } else {
      cancelSpeech();
      setBotTalking(false);
    }
  };

  const startSpeech = useCallback(() => {
      if (isBotTalking) {
        return;
      }
      setVolume(1);
      const synth = window.speechSynthesis;
      utterance.text = socialSkillFormData.voiceOver
      utterance.voice = voice;
      utterance.pitch = pitch;
      utterance.rate = speedRate;
      utterance.volume = 1;
      synth.speak(utterance);
      setBotTalking(true);
    },
    [isBotTalking, utterance, voice]
  );

  const restartSpeech = () => {
    cancelSpeech();
    setVolume(1)
    const synth = window.speechSynthesis;
    utterance.text = socialSkillFormData.voiceOver
    utterance.voice = voice;
    utterance.pitch = pitch;
    utterance.rate = speedRate;
    utterance.volume = 1;
    synth.speak(utterance);
    setBotTalking(true);
  }

  useEffect(() => {
    
    generatePNG();

    if (
      socialSkillFormData.voiceOver &&
      socialSkillFormData.voiceOver.trim() !== ""
    ) {
      initializeSpeechSynthesis();
    }
    // eslint-disable-next-line
  }, []);

  const adjustFontSize = (text, elementId) => {
    const wordCount = text.trim().length;
    const element = document.getElementById(elementId);
    if (element) {
      if (wordCount > 170) {
        element.style.fontSize = "13px";
      } else {
        element.style.fontSize = "15px";
      }
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const generatePNG = async () => {
    try {
      const container = document.createElement("div");
      container.style.visibility = "hidden";
      container.style.position = "absolute";
      container.style.width = "1024px";
      container.style.height = "768px";
      document.body.appendChild(container);

      container.innerHTML = ` <div id="container" className="relative w-96 h-96" >
      <img src="${SocialSkills}" alt="Social Skills" class="w-full h-1/2 object-contain" />
      <div class="absolute inset-0">
      <p class="absolute mt-[9rem] font-bold ml-48 ">  ${authReducer?.firstName} ${
        authReducer?.lastName
      } </p>
      <p class="absolute left-[17rem] font-bold mt-[9rem] ml-96 text-center">   ${formattedDate}</p>
      <span class= "absolute left-[35.5rem] font-bold mt-[11rem]  w-1/3 overflow-y-hidden h-8  text-start">${
        socialSkillFormData.socialSkill
      } </span>
      ${
        socialSkillFormData.step1
          ? ` <p id="step1"  class= "absolute left-[11.5rem] font-bold top-12 mt-[12.5rem] bg-red-0 w-3/4 overflow-y-hidden h-8 text-start"> ${socialSkillFormData.step1} </p>`
          : ""
      }
      ${
        socialSkillFormData.step2
          ? `<p id="step2" class= "absolute left-[11.5rem] font-bold top-24 mt-[12rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step2}</p>`
          : ""
      }
      ${
        socialSkillFormData.step3
          ? `<p id="step3" class= "absolute left-[11.5rem] font-bold top-36 mt-[11.5rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step3} </p>`
          : ""
      }
      ${
        socialSkillFormData.step4
          ? `<p id="step4" class= "absolute left-[11.5rem] font-bold top-48 mt-[11rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step4} </p>`
          : ""
      }
      ${
        socialSkillFormData.step5
          ? `<p id="step5" class= "absolute left-[11.5rem] font-bold top-60 mt-[10.3rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start">${socialSkillFormData.step5} </p>`
          : ""
      }
      ${
        socialSkillFormData.step6
          ? `<p id="step6" class= "absolute left-[11.5rem] font-bold top-72 mt-[9.8rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.step6} </p>`
          : ""
      }
      ${
        socialSkillFormData.plan1
          ? ` <p id="plan1"  class= "absolute left-[11.5rem] font-bold top-[23.5rem] mt-[10rem] bg-red-0 w-3/4 overflow-y-hidden h-8 text-start"> ${socialSkillFormData.plan1} </p>`
          : ""
      }
      ${
        socialSkillFormData.plan2
          ? `<p id="plan2" class= "absolute left-[11.5rem] font-bold top-[25.8rem] mt-[10rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.plan2} </p>`
          : ""
      }
      ${
        socialSkillFormData.plan3
          ? `<p id="plan3" class= "absolute left-[11.5rem] font-bold top-[28.2rem] mt-[10rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.plan3} </p>`
          : ""
      }
      ${
        socialSkillFormData.plan4
          ? `<p id="plan4" class= "absolute left-[11.5rem] font-bold top-[30.6rem] mt-[10rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.plan4} </p>`
          : ""
      }
      ${
        socialSkillFormData.plan5
          ? `<p id="plan5" class= "absolute left-[11.5rem] font-bold top-[32.9rem] mt-[10rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start">${socialSkillFormData.plan5} </p>`
          : ""
      }
      ${
        socialSkillFormData.plan6
          ? `<p id="plan6" class= "absolute left-[11.5rem] font-bold top-[35.3rem] mt-[10rem] bg-red-00 w-3/4 overflow-hidden h-8 text-start"> ${socialSkillFormData.plan6} </p>`
          : ""
      }
      ${
        socialSkillFormData.anticipate
          ? `<p id="anticipate" class= "absolute left-[13rem] font-bold top-[37.8rem] mt-[10rem] bg-red-00 w-4/5 overflow-hidden h-40  leading-[2rem] text-start indent-[20rem]">${socialSkillFormData.anticipate} </p>`
          : ""
      }
      </div>
      </div>`;

      if (socialSkillFormData.anticipate) {
        adjustFontSize(socialSkillFormData.anticipate, "anticipate");
      }
      if (socialSkillFormData.situation) {
        adjustFontSize(socialSkillFormData.situation, "situation");
      }
      if (socialSkillFormData.skillagain) {
        adjustFontSize(socialSkillFormData.skillagain, "skillagain");
      }

      container.style.visibility = "visible";

      const canvas = await html2canvas(container, {
        width: 1024,
        height: 1300,
      });

      const imageData = canvas.toDataURL("image/png");

      setDownloadedImage(imageData);
      document.body.removeChild(container);

      if (
        socialSkillFormData.rating.trim() !== "" ||
        socialSkillFormData.Ok ||
        socialSkillFormData.NeedImprovement ||
        socialSkillFormData.Good ||
        socialSkillFormData.Excellent
      ) {
        // Convert base64 to Blob
        const blob = await fetch(imageData).then((res) => res.blob());
        saveToolForm(blob, "Social-skills", navigate, location);
      }
    } catch (error) {
      console.error("Error generating PNG:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = downloadedImage;
    link.download = "SocialSkill-Form.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="absolute z-50 w-5/6 bg-transparent xl:w-[80vh]  lg:w-1/2 h-[80vh] bg-white rounded-lg  ">
        <div className="relative xsm:space-x-2 mb-4 mt-2 z-50 flex justify-end space-x-4 print:hidden px-4">
          <CustomButton onClick={handlePrint} buttonColor="primary">
            Print
          </CustomButton>
          <CustomButton onClick={handleDownload} buttonColor="primary">
            Download
          </CustomButton>
          <CustomButton
            buttonColor={"danger"}
            buttonType={"outline"}
            buttonSize={"sm"}
            onClick={() => {
              setShowSocialSkillForm(false);
              setTimeout(() => {
                window.botpressWebChat.sendEvent({
                  type: "proactive-trigger",
                  channel: "web",
                  payload: { text: "SocialSkill Form displayed!" },
                });
              }, 500);
              cancelSpeech();
              closeModal();
            }}
            customStyle={"mr-2"}
          >
            Close
          </CustomButton>

          {socialSkillFormData.voiceOver &&
            socialSkillFormData.voiceOver.trim() !== "" && (
              <div className="flex flex-row items-start absolute top-12">
                <button
                  type="button"
                  className="avatar-speech-button-wrapper bg-[#FFEACE] p-3 cursor-pointer rounded-md border-1 border-black"
                  onClick={() => {
                    setToggleSpeaker(!toggleSpeaker);
                    toggleVolume();
                  }}
                >
                  {toggleSpeaker ? (
                    <Speaker width={24} height={24} />
                  ) : (
                    <MuteSpeaker width={14} height={14} />
                  )}
                </button>
                {toggleSpeaker && (
                  <button
                    type="button"
                    className="avatar-speech-button-wrapper bg-[#FFEACE] p-1 cursor-pointer rounded-md ml-2 border-1 border-black"
                    onClick={() => {
                      restartSpeech();
                    }}
                  >
                    <RefreshIcon width={24} height={24} />
                  </button>
                )}
              </div>
            )}
        </div>
        <img
          src={downloadedImage}
          alt="SocialSkill"
          className="w-full h-full "
        />
      </div>
    </div>
  );
};

export default SocialSkillForm;
