import React, { useState , useEffect} from 'react';
import CustomModal from '../../../components/CustomModal';
import CustomButton from "../../../components/CustomButton";
import { useLocation, useNavigate } from 'react-router-dom';
import { makePostRequest } from '../../../utils/makeRequest';



const NewConversationModal = ({ isOpen, closeModal, onCreateConversation }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    // Use a useEffect to reset the state when the modal is closed
    if (!isOpen) {
      setTitle('');
      setDescription('');
    }
  }, [isOpen]);

  const navigate = useNavigate();
  const location = useLocation();
  
  const handleCreateConversation = async (e) => {
    e.preventDefault();
    
    if (!title || !description) {
      setError("Please fill in both title and description fields.");
      setTitle('');
      setDescription('')
      return;
    }

    const response = await makePostRequest({
      path: "/api/conversation/create-conversation", body: {
        conversationTitle: title,
        conversationDescription: description,
      },
      config: {
        headers: {
          "Content-Type": "application/json",
        },
      }, navigate, location
    });

    if (response.status === 201) {
      setError('');
      closeModal();  
      // Trigger the callback to fetch conversations
      if (typeof onCreateConversation === 'function') {
        onCreateConversation(response.data.data.bpConversationId);
      }
    } else {
      console.error('Failed to create conversation');
      setError("Failed to create conversation. Please try again.");
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={closeModal}
      title="Create a new tool"
      titleStyle={"w-full flex items-center justify-center mb-2"}
    >
      <form onSubmit={handleCreateConversation}>
        <input
          type="text"
          placeholder="My new tool"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full border rounded-md p-3 mb-4"
          style={{ fontSize: "14px" }}
        />
        <textarea
          placeholder="My new tool that helps me for..."
          value={description}
          maxLength={150}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full border rounded-md p-3 mb-4 h-32"
          style={{ fontSize: "14px" }}
          draggable="false"
        />
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex justify-end flex-row">
          <CustomButton
            buttonColor={"danger"}
            buttonType={"outline"}
            buttonSize={"sm"}
            onClick={(e) => {
              e.preventDefault(); 
              closeModal();
            }}
            customStyle={"mr-2"}
          >
            Cancel
          </CustomButton>
          <CustomButton
            buttonColor={"primary"}
            buttonType={"solid"}
            buttonSize={"lg"}
          >
            Create tool
          </CustomButton>
        </div>
      </form>
    </CustomModal>
  );
}

export default NewConversationModal;
