import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import ghostAnimation from "../../../sources/lotties/ghostAnimation.json";

const DataGrid = ({ showHeader, columns, rows, emptyMessage }) => {
  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((column) => ({ ...column, showInTable: true }))
  );
  const noOfColumns = columns.length;

  useEffect(() => {
    const updateColumns = () => {
      const screenWidth = window.innerWidth;
      const updatedColumns = visibleColumns.map((column, idx) => ({
        ...column,
        showInTable:
          screenWidth > 900 || idx === 0
            ? true
            : screenWidth > 720 && idx < noOfColumns - 1
            ? true
            : screenWidth > 540 && idx < noOfColumns - 2
            ? true
            : screenWidth > 360 && idx < noOfColumns - 3
            ? true
            : false,
      }));
      setVisibleColumns(updatedColumns);
    };

    window.addEventListener("resize", updateColumns);
    updateColumns();

    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  return (
    <table className="min-w-full table-auto">
      {showHeader && (
        <thead>
          <tr>
            {visibleColumns
              .filter((column) => column.showInTable)
              .map((column, index) => (
                <td key={index} className="p-2">
                  {column.label}
                </td>
              ))}
          </tr>
        </thead>
      )}

      <tbody>
        {rows.map((row, rowIndex) => (
          <Disclosure key={rowIndex}>
            <tr className="border-t border-transparent">
              {visibleColumns
                .filter((column) => column.showInTable)
                .map((column, colIndex) => {
                  const CellIcon = column.icon ? column.icon : null;
                  const iconColor = row.isActive
                    ? "fill-secondary"
                    : "fill-[#00b45c]";
                  const iconBackground = row.isActive
                    ? "bg-secondary"
                    : "bg-[#00b45c]";
                  return (
                    <td key={colIndex} className="p-2">
                      {column.icon ? (
                        <div className="flex justify-left items-center gap-2">
                          <div
                            className={`${
                              column.showIconBackground
                                ? `${iconBackground ?? ""} ${
                                    column.backgroundPadding ?? "p-2"
                                  } border rounded-full`
                                : ""
                            }`}
                          >
                            <CellIcon
                              className={` h-6 w-6 ${
                                column.fillIcon
                                  ? iconColor + " text-white" ?? "text-black"
                                  : "text-black"
                              }`}
                            />
                          </div>
                          {row[column.accessor]}
                        </div>
                      ) : (
                        row[column.accessor]
                      )}
                    </td>
                  );
                })}

              <td>
                <DisclosureButton className="text-blue-500 md:hidden">
                  View More
                </DisclosureButton>
              </td>
            </tr>
            <DisclosurePanel as="tr" className={`flex flex-col ml-4`}>
              {visibleColumns
                .filter((col) => !col.showInTable)
                .map((column, idx) => {
                  const CellIcon = column.icon ? column.icon : null;
                  const iconColor = row.isActive
                    ? "fill-secondary"
                    : "fill-[#00b45c]";
                  const iconBackground = row.isActive
                    ? "bg-secondary"
                    : "bg-[#00b45c]";
                  return (
                    <td
                      key={idx}
                      className="p-2 flex justify-left items-center gap-2"
                    >
                      <div>
                        {column.icon ? (
                          <div
                            className={`${
                              column.showIconBackground
                                ? `${iconBackground ?? ""} ${
                                    column.backgroundPadding ?? "p-2"
                                  } border rounded-full`
                                : ""
                            }`}
                          >
                            <CellIcon
                              className={` h-6 w-6 ${
                                column.fillIcon
                                  ? iconColor + " text-white" ?? "text-black"
                                  : "text-black"
                              }`}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>{row[column.accessor]}</div>
                    </td>
                  );
                })}
            </DisclosurePanel>
          </Disclosure>
        ))}
        {rows.length === 0 && (
          <tr>
            <td colSpan={visibleColumns.length} className="p-2 text-center">
              <div className="w-full h-full flex flex-col items-center justify-center">
                <div className="w-52 h-52 -mb-24">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: ghostAnimation,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </div>
                <p className="mt-24 text-xl">
                  {" "}
                  {emptyMessage ? emptyMessage : "No data available"}{" "}
                </p>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DataGrid;
